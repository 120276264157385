import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch } from "react-redux";
import { removeAllItemsFromCart } from "../../actions/cartAction";
import { useAlert } from "react-alert";

const ConfirmationModalHome = ({ isOpenConfirm, setIsOpenConfirm, reset }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  function closeConfirmModal() {
    setIsOpenConfirm(false);
  }
  const deleteAllCartItems = () => {
    dispatch(removeAllItemsFromCart());
    alert.success("All items removed from cart");
    reset();
    setIsOpenConfirm(false);
  };
  return (
    <>
      <Transition appear show={isOpenConfirm} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 "
          style={{ zIndex: "67" }}
          onClose={closeConfirmModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black/20 backdrop-blur-[3px] w-full " />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-xs p-4 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title className="text-center text-xl font-bold px-5 mb-5">
                  Are You Sure you want to Remove All cart items ?
                </Dialog.Title>

                <div className="flex mt-2 gap-2">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-bold uppercase text-white bg-primaryColor border border-transparent rounded-[10px] hover:bg-primaryDarkColor w-2/3"
                    onClick={deleteAllCartItems}
                  >
                    Yes, I'm Sure
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-bold uppercase text-slate-500 bg-slate-100 border border-transparent rounded-[10px] hover:bg-slate-200 w-1/3 "
                    onClick={closeConfirmModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ConfirmationModalHome;
