import React from "react";
import Moment from "react-moment";
// import Moment from "react-moment";
import { useSelector } from "react-redux";
import { ReactComponent as NavIcon } from "../../assets/images/heebeeIcon.svg";
// import moment from "moment-timezone";

const LogoutReportInvoice = ({ componentref, orderData, data }) => {
  var today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  var dateTime = date + " " + time;

  const branchNameFromStore = useSelector(
    (state) => state.userDetails?.user?.branch
  );
  // console.log(data);
  return (
    <div className="p-[5px]" ref={componentref}>
      {/* <div className="flex justify-between items-center mb-5">
        <h3 className="font-bold text-sm">
          <Moment format="Do MMM">
            {today}
          </Moment>
        </h3>
        <h3 className="font-bold text-sm">
          <Moment format="YYYY">
            {today}
          </Moment>
        </h3>
      </div> */}
      <div className="flex flex-col justify-center items-center mt-4">
        <div className="flex justify-center items-end relative">
          <NavIcon className="w-20 h-20" />
          <span className="absolute bottom-1 right-0">&#174;</span>
        </div>
        <h3 className="text-lg font-bold uppercase">HeeBee Coffee Private Limited</h3>
        <h3 className="text-md font-bold">
          <Moment format="Do MMM, YYYY">
            {today}
          </Moment>
        </h3>
        <h3 className="text-md font-bold uppercase border-2 border-black px-2 py-1/2">
          {data.branch_name}
        </h3>
      </div>
      <div className="flex justify-between items-center mb-10">
        <h3 className="text-md font-bold uppercase border-2 border-black px-2 py-1/2">
          {data.employee_name}
        </h3>
        <h3 className="text-md font-bold uppercase border-2 border-black px-2 py-1/2">
          <Moment format="hh:mm A">
            {data.login_in_time}
          </Moment> - <Moment format="hh:mm A">
            {data.logout_time}
          </Moment>
        </h3>
      </div>

      <div className="flex flex-col justify-center items-center  border-y-2 border-black border-dashed mb-4">
        <h3 className="text-md font-bold uppercase text-center border-b-2 border-black px-2 py-1 border-dashed w-full">
          Cash Sale Report
        </h3>
        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Total Cash Collection
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.total_cash_collection * 100) / 100 || 0}
          </h3>
        </div>
        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Cash from Cash sales
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.total_cash_from_cash_sales * 100) / 100 || 0}
          </h3>
        </div>

        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Cash from wallet Addition
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.total_cash_from_wallet_add * 100) / 100 || 0}
          </h3>
        </div>

        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Cash from card + cash
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.total_cash_from_card_Plus_cash * 100) / 100 || 0}
          </h3>
        </div>

        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Cash from wallet + cash
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.total_cash_from_wallet_Plus_cash * 100) / 100 || 0}
          </h3>
        </div>

        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Petty cash
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.petty_cash * 100) / 100 || 0}
          </h3>
        </div>

      </div>

      <div className="flex flex-col justify-center items-center  border-y-2 border-black border-dashed mb-4">
        <h3 className="text-md font-bold uppercase text-center border-b-2 border-black px-2 py-1 border-dashed w-full">
          Digital Sale Report
        </h3>
        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Total Digital Collection
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.total_digital_collection * 100) / 100 || 0}
          </h3>
        </div>
        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Card Sales
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.total_digital_from_card_sales * 100) / 100 || 0}
          </h3>
        </div>
        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            card + cash sales
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.total_digital_from_card_Plus_cash * 100) / 100 || 0}
          </h3>
        </div>

        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            wallet Addition
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.total_digital_from_wallet_add * 100) / 100 || 0}
          </h3>
        </div>

        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Wallet + card
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.total_digital_from_wallet_Plus_card * 100) / 100 || 0}
          </h3>
        </div>


      </div>

      <div className="flex flex-col justify-center items-center  border-y-2 border-black border-dashed mb-4">
        <h3 className="text-md font-bold uppercase text-center border-b-2 border-black px-2 py-1 border-dashed w-full">
          Wallet Collection Report
        </h3>
        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Total Wallet Collection
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.total_wallet_collection * 100) / 100 || 0}
          </h3>
        </div>
        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Collection by cash
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.total_cash_from_wallet_add * 100) / 100 || 0}
          </h3>
        </div>
        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            collection by digital
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.total_digital_from_wallet_add * 100) / 100 || 0}
          </h3>
        </div>


      </div>

      <div className="flex flex-col justify-center items-center  border-y-2 border-black border-dashed mb-4">
        <h3 className="text-md font-bold uppercase text-center border-b-2 border-black px-2 py-1 border-dashed w-full">
          Wallet Spending Report
        </h3>

        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Total Wallet spends
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.total_wallet_spnd_collection * 100) / 100 || 0}
          </h3>
        </div>

        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Wallet spends
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.total_wallet_spnd_from_wallet_sales * 100) / 100 || 0}
          </h3>
        </div>

        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Wallet + cash spends
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.total_wallet_spnd_from_wallet_Plus_cash * 100) / 100 || 0}
          </h3>
        </div>

        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            wallet + Card spends
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.total_wallet_spnd_from_card_Plus_wallet * 100) / 100 || 0}
          </h3>
        </div>



      </div>

      <div className="flex flex-col justify-center items-center  border-y-2 border-black border-dashed mb-4">
        <h3 className="text-md font-bold uppercase text-center border-b-2 border-black px-2 py-1 border-dashed w-full">
          Digital Account Report
        </h3>
        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Digital Account Sale
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.total_account_sale * 100) / 100 || 0}
          </h3>
        </div>
        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Delivery Charge
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.total_account_delivery_charges * 100) / 100 || 0}
          </h3>
        </div>
        <div className="flex justify-between items-center w-full px-1 py-1 border-t-2 border-black border-dashed">
          <h3 className="text-xs font-bold capitalize">
            Sales as per Delivery
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.sales_per_delievery * 100) / 100 || 0}
          </h3>
        </div>


      </div>

      <div className="flex justify-between items-center  border-y-2 border-black  mb-4 px-1 py-1/2">
        <h3 className="text-md font-bold uppercase ">
          Net Sales
        </h3>
        <h3 className="text-md font-bold uppercase">
          &#8377;{Math.round(data.netsales * 100) / 100 || 0}
        </h3>
      </div>

      <div className="flex justify-between items-center mt-16 mb-5">
        <h3 className="text-md font-bold capitalize border-t-[2px] border-dashed border-black">
          Employee Sign.
        </h3>
        <h3 className="text-md font-bold capitalize border-t-[2px] border-dashed border-black">
          Manager Sign.
        </h3>
      </div>

    </div>
  );
};

export default LogoutReportInvoice;
