import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  SearchIcon,
  MenuAlt2Icon,
  XIcon
} from "@heroicons/react/outline";
import Sidebar from "../../components/layout/Sidebar";
import Notification from "../../components/layout/Notification";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Product from "../../components/product/Product";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getProductsList } from "../../actions/productActions";
import debounce from "lodash/debounce";
import { getCategory } from "../../actions/categoryAction";

import Loader from "../../components/loader/Loader";
import CashierAmountModal from "../../components/home/CashierAmountModal";

const LeftSectionHome = () => {
  const navigate = useNavigate();

  /*  const inputRef = useRef(null);
   const [t, setT] = useState(0);
   useEffect(() => {
     if (inputRef.current !== null) inputRef.current.focus();
     setTimeout(() => {
       setT(t => t + 1);
     }, 1000)
   }, [inputRef.current, t]) */

  const dispatch = useDispatch();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  const [isOpenMainSidebar, setIsOpenMainSidebar] = useState(false);
  const [isOpenCashierModal, setIsOpenCashierModal] = useState(false);


  const [productSearchQuery, setProductSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [category, setCategory] = useState("Hot Coffee");



  const ToggleMainSidebar = () => {
    isOpenMainSidebar === true
      ? setIsOpenMainSidebar(false)
      : setIsOpenMainSidebar(true);
  };

  // get userinfo from redux store
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // get categories from redux store
  const getCategories = useSelector((state) => state.categories);

  const { categories: categoryData } = getCategories;
  const categoryList = categoryData?.categories || [];

  // get product from redux store
  const productListFromStore = useSelector((state) => state.product);

  const { loading: isProductListLoading, product: productData } =
    productListFromStore;
  const productList = productData?.data || [];

  const branchIdfromStore = useSelector(
    (state) => state.userDetails?.user?.branch_id
  );
  const branch = !!branchIdfromStore;

  const totalCount = productData?.total_product || 0;
  const itemPerPage = productData?.per_page || 10;

  const totalPageCount = Math.ceil(totalCount / itemPerPage);


  const getProductsListHandler = useCallback(
    (searchQuery = "", currentPage, reset, category = "") =>
      dispatch(
        getProductsList(
          searchQuery,
          currentPage,
          reset,
          category,
          branchIdfromStore
        )
      ),

    [dispatch, branchIdfromStore]
  );

  const getProductsListHandlerDebounced = useMemo(
    () => debounce(getProductsListHandler, 250),
    [getProductsListHandler]
  );

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    if (productSearchQuery === "") {
      getProductsListHandlerDebounced(
        productSearchQuery,
        currentPage,
        true,
        category?.category_id
      );
    }
  }, [productSearchQuery])

  useEffect(() => {
    if (branchIdfromStore !== undefined) {
      getProductsListHandlerDebounced(
        "",
        currentPage,
        true,
        category?.category_id
      );
    }
  }, [
    getProductsListHandlerDebounced,
    category,
    currentPage,
    branchIdfromStore,
  ]);

  const searchProduct = () => {
    getProductsListHandlerDebounced(
      productSearchQuery,
      currentPage,
      true,
      category?.category_id
    );
  }

  const disablePrevButton = currentPage === 1;
  const disableNextButton = currentPage === totalPageCount;

  const handleNextClick = useCallback(() => {
    setCurrentPage((v) => v + 1);
  }, []);
  const handlePrevClick = useCallback(() => {
    setCurrentPage((v) => v - 1);
  }, []);

  useEffect(() => {
    if (branch) {
      dispatch(getCategory(branchIdfromStore));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchIdfromStore]);

  useEffect(() => {
    let cashier_popup = sessionStorage.getItem('cashier_popup');
    if (!cashier_popup) {
      setIsOpenCashierModal(true);
    }
  }, [])
  //if(!isOpenCashierModal) return null;


  return (
    <>
      <div className="w-full md:w-2/3 h-screen relative  z-25">
        <div
          className="top-bar-left-home m-[5px] p-1 fixed top-0 rounded-[10px] shadow-sm bg-slate-50 "
          style={{ zIndex: "1 !important" }}
        >
          <div className="flex justify-between items-center">
            <button
              className="button-pimary-light p-2 bg-primaryLightColor25 text-primaryColor rounded-[10px]"
              onClick={ToggleMainSidebar}
            >
              <MenuAlt2Icon className="h-6 w-6" />
            </button>
            <div className="search-input-div relative grow">
              <input
                onChange={(e) => {
                  setProductSearchQuery(e.target.value);
                  setCurrentPage(1);
                }}
                onKeyPress={(e) => { if (e.charCode === 13) searchProduct() }}
                value={productSearchQuery}
                type="text"
                //ref={inputRef}
                placeholder="Search item name/sku"
                className="w-[98%] pl-[40px] rounded-[10px] p-2 mx-2 bg-primaryLightColorInput text-textColor focus:outline-primaryColor m-auto block"
              />
              <SearchIcon className="h-6 w-6 absolute top-[8px] left-[9px] text-primaryColor mx-2" />
              <XIcon className="h-6 w-6 absolute top-[8px] right-[9px] text-primaryColor mx-2 cursor-pointer" onClick={() => setProductSearchQuery("")} />
            </div>

            <div className="flex gap-1">
              <Notification />
            </div>
          </div>
        </div>
        <div
          className="top-bar-left-home  m-[5px] p-1 fixed top-[55px] rounded-[10px] shadow-sm bg-slate-50 "
          style={{ zIndex: "0 !important" }}
        >
          <div className="home-category-section  ">
            {categoryList.length > 0 && (
              <Carousel
                responsive={responsive}
                swipeable={true}
                className="py-0 w-100"
              >
                <div
                  className={`category-item category-item-active shadow-sm me-2 p-1`}
                  onClick={() => {
                    setCategory("");
                  }}
                >
                  <div
                    className={`flex items-center bg-primaryLightColorInput rounded-[10px] hover:bg-primaryLightColor30  ${category === category?.category_id
                      ? " bg-primaryLightColor30"
                      : " bg-primaryLightColorInput"
                      }`}
                  >
                    <div className="p-1">
                      <img
                        src="https://heebeetestapi.quadbtech.com/placeholder.png"
                        alt="category"
                        className="w-[40px] h-[40px] rounded-[10px]"
                      />
                    </div>
                    <div className="category-name ml-1 ">
                      <h6 className="font-bold uppercase leading-tight text-sm">All Items</h6>
                    </div>
                  </div>
                </div>

                {categoryList.map((item) => (
                  <div
                    key={item.category_id}
                    className={`category-item category-item-active shadow-sm mr-2 p-1 `}
                    onClick={() => {
                      setCategory(item);
                      setCurrentPage(1);
                    }}
                  >
                    <div
                      className={`flex items-center bg-primaryLightColorInput rounded-[10px] hover:bg-primaryLightColor30  ${item.category_id === category?.category_id
                        ? " bg-primaryLightColor30"
                        : " bg-primaryLightColorInput"
                        }`}
                    >
                      <div className="p-1">
                        <img
                          src={item.category_list?.card_img}
                          alt="category"
                          className="w-[40px] h-[40px] rounded-[10px]"
                        />
                      </div>
                      <div className="category-name ml-1">
                        <h6 className="font-bold uppercase leading-tight text-sm">
                          {item.category_list?.category_name}
                        </h6>
                      </div>
                    </div>
                  </div>
                ))}
              </Carousel>
            )}
          </div>
        </div>

        <div className="middle-bar-left-home m-[5px] px-2 rounded-[10px] absolute top-[130px] bottom-[55px] pb-2">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
            {isProductListLoading ? (
              <div
                style={{
                  position: "fixed",
                  left: "0px",
                  top: "0px",
                  width: "100%",
                  height: "100%",
                  zIndex: "999",
                }}
              >
                <Loader />
              </div>
            ) : (
              productList.map((product, i) => {
                return <Product key={i} product={product} />;
              })
            )}
          </div>
        </div>
        <div className="bottom-bar-left-home md:m-[5px] p-1 fixed bottom-0 rounded-[10px] shadow-sm bg-slate-50">
          <div className="flex justify-between items-center">
            {isProductListLoading ? (
              <h6 className="font-bold text-mutedColor ml-1">Loading...</h6>
            ) : (
              <h6 className="font-bold text-mutedColor ml-1">
                {productList.length} of {totalCount}
              </h6>
            )}
            <div className="flex justify-center items-center">
              <button
                className="button-pimary-light p-2 bg-primaryLightColor25 text-primaryColor rounded-l-[10px]"
                onClick={handlePrevClick}
                disabled={disablePrevButton}
              >
                <ChevronLeftIcon className="h-6 w-6" />
              </button>
              <button className="button-pimary-light p-2 bg-primaryLightColor25 text-primaryColor font-bold cursor-default">
                Page {currentPage}
              </button>
              <button
                className="button-pimary-light p-2 bg-primaryLightColor25 text-primaryColor rounded-r-[10px]"
                onClick={handleNextClick}
                disabled={disableNextButton}
              >
                <ChevronRightIcon className="h-6 w-6" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Sidebar
        isOpenMainSidebar={isOpenMainSidebar}
        setIsOpenMainSidebar={setIsOpenMainSidebar}
      />

      <CashierAmountModal isOpenCashierModal={isOpenCashierModal} setIsOpenCashierModal={setIsOpenCashierModal} />
    </>
  );
};

export default LeftSectionHome;
