import {
  XIcon,
  ChevronRightIcon,
  DocumentTextIcon,
} from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import OrderItemsSidebar from "./OrderItemsSidebar";
// import axios from "axios";
import { get } from "lodash";
import store from "../../store";
import Moment from "react-moment";
import orders from "../../api/orders";
import { ShoppingCartIcon } from "@heroicons/react/outline";
import Modal from "../product/Modal";

const OrderHistorySidebar = ({
  isOpenOrderHistorySidebar,
  setIsOpenOrderHistorySidebar,
  customerInfoRenderData,
}) => {
  const [isOpenOrderItemsSidebar, setIsOpenOrderItemsSidebar] = useState(false);
  const [orderItemData, setOrderItemData] = useState({});
  const state = useSelector(state => state);
  const [isOpen, setIsOpen] = useState(false);
  const [product, setProduct] = useState(null);

  const [orderDetails, setOrderDetails] = useState({})
  let popularItems = JSON.parse(sessionStorage.getItem("popularItems"));
  if (popularItems === null) popularItems = [];



  const { lastCustomerOrders } = useSelector(
    (state) => state.lastCustomerOrder
  );
  // const { loading } = useSelector((state) => state.lastCustomerOrder);

  const ToggleOrderHistorySidebar = () => {
    if (isOpenOrderHistorySidebar === true) {
      setIsOpenOrderHistorySidebar(false);
    }
    else {
      // setPopularItems(null);
      setIsOpenOrderHistorySidebar(true);
    }
  };

  const showParticularOrderDetail = (item) => {
    const token = get(store.getState(), "userLogin.userInfo.token");
    const config = {
      headers: {
        token: token,
      },
    };


    orders
      .get(
        `/fetch_order_detail?order_id=${item?.order_id}`,
        config
      )
      .then((res) => {
        setOrderDetails(res.data.data)
        if (res.data.data === "Token does not match") {
          sessionStorage.clear();
          window.location.href = "/login";
        }
        return res.data.data;
      });
  };

  const addToCart = (item) => {
    const token = get(store.getState(), "userLogin.userInfo.token");
    const config = {
      headers: {
        token: token,
      },
    };
    orders
      .get(
        `/get_single_product?product=${item.product_id}`, config
      )
      .then((res) => {
        setProduct(res.data.data);
        setIsOpen(true);
      });
  }

  const ToggleOrderItemsSidebar = (item) => {
    setOrderItemData(item);
    showParticularOrderDetail(item);
    isOpenOrderItemsSidebar === true
      ? setIsOpenOrderItemsSidebar(false)
      : setIsOpenOrderItemsSidebar(true);
  };


  const customerName = (!!customerInfoRenderData?.first_name) ? (customerInfoRenderData?.first_name) : customerInfoRenderData[0]?.first_name;
  const customerMobileNo = (!!customerInfoRenderData?.mobile_no) ? (customerInfoRenderData?.mobile_no) : customerInfoRenderData[0]?.mobile_no;



  return (
    <>
      <div
        className={`sidebar-order-history p-2 ${isOpenOrderHistorySidebar === true ? "active" : ""
          }`}
      >
        <div className="sidebar-header p-1 mb-2">
          <div className="flex justify-between items-center">
            <div>
              <h6 className="text-gray-800 font-bold text-lg leading-tight uppercase">
                Order History
              </h6>
              <h6 className="text-gray-600 font-bold text-sm leading-tight capitalize">
                {customerName}{" "}
                <span className="lining-num">
                  {customerMobileNo}
                </span>
              </h6>
            </div>
            <button
              className="button-pimary-light p-2 bg-primaryLightColor25 text-primaryColor rounded-[10px]"
              onClick={ToggleOrderHistorySidebar}
            >
              <XIcon className="h-6 w-6 close-icon" />
            </button>
          </div>
        </div>
        <div className=" overflow-auto sidebar-body  p-1 ">
          {lastCustomerOrders &&
            lastCustomerOrders.map((item, i) => {

              return (
                <div key={i}>
                  <div className="w-full  pr-1">
                    <div
                      className="flex items-center justify-between p-2 bg-white rounded-[10px] shadow-sm mb-2 border-2 border-primaryColor/25 cursor-pointer"
                      onClick={() => ToggleOrderItemsSidebar(item)}
                    >
                      <div className="flex items-center gap-1">
                        <DocumentTextIcon className="w-9 h-9 text-primaryColor/30" />
                        <div>
                          <h6 className="font-bold text-gray-700 text-md leading-none">
                            {item.order_id}
                          </h6>
                          <Moment format="MMM D, YYYY hh:mm a" className="font-bold text-gray-500 text-xs lining-nums">
                            {item.createdAt}
                          </Moment>
                        </div>
                      </div>
                      <button className="text-primaryColor hover:text-primaryDarkColor">
                        <ChevronRightIcon className="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <hr />
        {
          popularItems !== null && popularItems.length > 0 &&
          <div className=" overflow-auto sidebar-body  p-1 mt-2 ">
            <h6 className="text-gray-800 font-bold text-lg leading-tight uppercase">
              Popular Items
            </h6>
              {popularItems.map((item, i) =>
                <div key={i} className="flex flex-row justify-between items-center w-full gap-2 my-2 cursor-pointer" onClick={() => {
                  addToCart(item);
                }}>
                  <div className="flex flex-row gap-2">
                    <div className="flex-none relative w-[60px] h-[60px] rounded-[10px] cursor-pointer ">
                      <img
                        alt="orderitempic"
                        className="w-full h-full rounded-[10px] object-cover"
                        src={item.product_list.card_img}
                      />
                    </div>

                    <div>
                      <h2 className="font-bold text-gray-800 capitalize text-md line-clamp-1 leading-tight cursor-pointer">
                        {item.product_list.product_name}
                      </h2>
                      <div className="font-bold text-sm text-gray-500  leading-tight capitalize truncate">
                        <p>Food Type: {item.product_list.food_type}</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center items-center w-8 h-8 rounded-md  hover:bg-primaryColor/20 cursor-pointer">
                    <button className="text-primaryColor hover:text-primaryDarkColor  ">
                      <ChevronRightIcon className="w-5 h-5" onClick={() => {
                        addToCart(item);
                      }} />
                    </button>
                  </div>
                </div>
              )}
            </div>
        }
      </div>

      <div
        className={`sidebar-order-history-overlay ${isOpenOrderHistorySidebar === true ? "active" : ""
          }`}
      ></div>
      <OrderItemsSidebar
        isOpenOrderItemsSidebar={isOpenOrderItemsSidebar}
        setIsOpenOrderItemsSidebar={setIsOpenOrderItemsSidebar}
        orderItemData={orderItemData}
        orderDetails={orderDetails}
      />
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} product={product} edit={false} editItem={null} />
    </>
  );
};

export default OrderHistorySidebar;
