import {
  ChevronLeftIcon,
  ShoppingCartIcon,
  XIcon,
  MenuAlt2Icon,
  ChevronRightIcon,
} from "@heroicons/react/outline";
// import axios from "axios";
import Notification from "../components/layout/Notification";

import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import OnHoldOrderDetailsDisplay from "../components/home/OnHoldOrderDetailsDisplay";
import OnHoldOrdersList from "../components/home/OnHoldOrdersList";
import OnHoldOrdersSearchBar from "../components/home/OnHoldOrdersSearchBar";
import Filter from "../components/layout/Filter";
import { connect, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../actions/orderListAction";
import { get } from "lodash";
import store from "../store";
import Nodata from "../components/home/Nodata";
import Sidebar from "../components/layout/Sidebar";
import orders from "../api/orders";
/* const url =
  "/get_emp_hold_orders/1?employee=01a1e1ec-9cc6-4808-a3cc-2d6143454fc8&branch=3e2f18a3-7143-4ada-8e97-b4745c63f06a"; */

let allOnHoldOrders = [];
let totalOrders = 0;

const getOnHoldOrders = async (currentPage, employee_id, branch_id) => {
  const token = get(store.getState(), "userLogin.userInfo.token");
  const config = {
    headers: {
      token: token,
    },
  };
  try {
    const response = await orders.get(
      `/get_emp_hold_orders/${currentPage}?employee=${employee_id}&branch=${branch_id}`,
      config
    );

    if (response.data.data === "Token does not match") {
      sessionStorage.clear();
      window.location.href = "/login";
    }
    allOnHoldOrders = response.data.data;
    totalOrders = response.data.total_orders;
    return response.data.data;
  } catch (error) {
  }
};

const OnHoldOrders = ({ showLoader, hideLoader }) => {
  const [visible, setVisible] = React.useState(true);

  const [onHoldOrdersData, setOnHoldOrdersData] = useState([]);
  const [isOpenMainSidebar, setIsOpenMainSidebar] = useState(false);

  const [searchedWord, setSearchedWord] = useState("");

  const ToggleMainSidebar = () => {
    setIsOpenMainSidebar(!isOpenMainSidebar);
  };

  const handleSetSearchWord = (word) => {
    setSearchedWord(word);
  };

  const employee_id = useSelector(
    (state) => state.userDetails?.user?.employee_id
  );
  const branch_id = useSelector((state) => state.userDetails?.user?.branch_id);

  const [currentPage, setCurrentPage] = useState(1);

  const totalorderOnOnePage = onHoldOrdersData?.length || 0;

  const totalPageCount = Math.ceil(totalOrders / 10) || 0;

  const handleNextClick = useCallback(() => {
    setCurrentPage((v) => v + 1);
  }, []);
  const handlePrevClick = useCallback(() => {
    setCurrentPage((v) => v - 1);
  }, []);

  const [orderId, setOrderId] = useState("");

  useEffect(() => {
    showLoader();
    setTimeout(() => {
      hideLoader();
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (branch_id !== undefined && employee_id !== undefined) {
      getOnHoldOrders(currentPage, employee_id, branch_id).then((data) => {
        setOnHoldOrdersData(data);
        if (data !== undefined) {
          setOrderId(data[0].order_id);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, branch_id, employee_id]);

  useEffect(() => {
    if (searchedWord) {
      if (onHoldOrdersData !== undefined) {
        setOnHoldOrdersData(
          onHoldOrdersData.filter((order) => {
            return (
              order.order_id.toLowerCase().includes(searchedWord) ||
              (order.customer_no &&
                order.customer_no.toLowerCase().includes(searchedWord))
            );
          })
        );
      }
    } else {
      setOnHoldOrdersData(allOnHoldOrders);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedWord]);

  const navigate = useNavigate();

  const disablePrevButton = currentPage === 1;
  const disableNextButton = currentPage === totalPageCount;

  return (
    <>
      <div>
        <button
          className=" font-bold  py-2 px-4 s leading-normal sm:hidden bg-stone-50/50 hover:bg-primaryLightColor25 text-primaryColor p-2 rounded-[10px] backdrop-blur-[3px] block md:hidden shadow fixed bottom-[60px] right-[20px]  z-50"
          onClick={() => setVisible(!visible)}
        >
          {visible ? (
            <ShoppingCartIcon className="text-3xl h-5 w-5" />
          ) : (
            <XIcon className="text-4xl h-5 w-5" />
          )}
        </button>
      </div>

      <div className="bg-stone-200 h-screen flex flex-col md:flex-row ">
        {/* left */}
        <section
          className={`left-section w-full md:w-[700px] h-full flex flex-col${
            visible ? " active" : ""
          }`}
        >
          <div className="flex justify-between items-center bg-stone-50 borderRadius rounded-[10px] m-2 p-1">
            <div className="button-pimary-light p-2 bg-primaryLightColor25 text-primaryColor rounded-[10px] cursor-pointer">
              <ChevronLeftIcon
                className="h-6 w-6"
                onClick={() => {
                  navigate("/");
                }}
              />
            </div>

            <div className="flex-end w-64">
              <h1 className="text-2xl text-center text-primaryColor font-bold">
                Onhold Orders
              </h1>
            </div>
            <Filter />
          </div>

          {/* //Search Bar// */}
            <div className="search-bar bg-stone-50 m-2 p-2  rounded-[10px]">
              {" "}
              <OnHoldOrdersSearchBar
                handleSetSearchWord={handleSetSearchWord}
              />
          </div>

          {/* //display onHoldOrder List// */}
          <div className="right-sidebar-orders h-100 md:max-h-[611px] overflow-y-auto cursor-pointer">
            {onHoldOrdersData?.map((onHoldOrder) => {
              return (
                <OnHoldOrdersList
                  key={onHoldOrder.order_id}
                  onHoldOrder={onHoldOrder}
                  setOrderId={setOrderId}
                />
              );
            }) || <Nodata height={100} />}
          </div>
          <div className="flex justify-between items-center">
            <h6 className="font-bold text-mutedColor ml-1">
              {totalorderOnOnePage} of {totalOrders}
            </h6>
            <div className="flex items-center">
              <button
                className="button-pimary-light p-2 bg-primaryLightColor25 text-primaryColor rounded-l-[10px]"
                onClick={handlePrevClick}
                disabled={disablePrevButton}
              >
                <ChevronLeftIcon className="h-6 w-6" />
              </button>
              <button className="button-pimary-light p-2 bg-primaryLightColor25 text-primaryColor font-bold cursor-default">
                Page {currentPage}
              </button>
              <button
                className="button-pimary-light p-2 bg-primaryLightColor25 text-primaryColor rounded-r-[10px]"
                onClick={handleNextClick}
                disabled={disableNextButton}
              >
                <ChevronRightIcon className="h-6 w-6" />
              </button>
            </div>
          </div>
        </section>

        {/* //Right Section// */}
        {orderId ? (
          <OnHoldOrderDetailsDisplay orderId={orderId} />
        ) : (
          <div className="right-section w-full  h-[calc(100%_-_50px)] relative">
            <div className="flex justify-between items-center bg-stone-50 borderRadius rounded-[10px] p-1 m-2">
              <div
                className="p-2 bg-primaryLightColor25 rounded-[10px] text-primaryColor cursor-pointer"
                onClick={ToggleMainSidebar}
              >
                <MenuAlt2Icon className="w-6 h-6" />
              </div>

              <h1 className="font-bold text-2xl text-[#999999]">
                <span className="text-[#17202A]"></span>
              </h1>

              <Notification />
            </div>

            <Sidebar
              isOpenMainSidebar={isOpenMainSidebar}
              setIsOpenMainSidebar={setIsOpenMainSidebar}
              width="100%"
              height="100%"
            />
            <Nodata height={100} />
          </div>
        )}
      </div>
    </>
  );
};

export default connect(null, { showLoader, hideLoader })(OnHoldOrders);
