import React, { useCallback, useEffect, useState } from "react";
import Logo from "../../assets/images/logo.png";
import {
  LoginIcon,
  DocumentTextIcon,
  CurrencyRupeeIcon,
} from "@heroicons/react/outline";
import CountUp from "react-countup";
// import { Link } from "react-router-dom";
import orders from "../../api/orders";
import LogoutEmpOrderItem from "./logoutEmpOrderItem";
import { LoaderSpinner } from "../loader/LoaderSpinner";
import { AiOutlinePrinter } from "react-icons/ai";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
// import Billpdf from "./Billpdf";
// import NewBill from "./NewBill";
import LogoutReportInvoice from "./LogoutReportInvoice";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

const LogoutEmployeeOrders2 = () => {
  const userData = JSON.parse(sessionStorage.getItem("userdetails"));
  const employee_cashier_id = sessionStorage.getItem("employee_cashier_id");
  const componentref = useRef();
  const [data, setData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const total = data?.total_orders;
  const [totalOrders, setTotalOrders] = useState(total);
  const [totalItemsLength, setTotalItemsLength] = useState(0);


  const Sfunction = useCallback(async () => {
    try {
      // eslint-disable-next-line no-unused-vars
      const data = await orders
        .get(
          `/logout_employee_orders_v2/${currentPage}?employee=${userData.employee_id}&branch=${userData.branch_id}&emp_cashier_id=${employee_cashier_id}`
        )
        .then((res) => {
          // console.log(res.data.data[0]);
          setData(res.data.data[0]);
          //setOrderData(res.data.data);
          //console.log(res.data.status);
        });
      setLoading(true);
    } catch (e) {
    }
  }, [
    currentPage,
    userData.employee_id,
    employee_cashier_id,
    userData.branch_id,
  ]);

  useEffect(() => {
    Sfunction();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Sfunction]);

  const filterItem = (status) => {
    const updatedItems = data.all_orders.filter((curElem) => {
      return curElem.status === status;
    });
    setOrderData(updatedItems);
    checkFilter(status);
  };

  const handlePrint = useReactToPrint({
    content: () => componentref.current,
  });

  const LoginRedirect = () => {
    sessionStorage.removeItem("userdetails");
    sessionStorage.removeItem("employee_cashier_id");
    window.location.href = "/login";
  };

  const handleNextClick = useCallback(() => {
    setCurrentPage((v) => v + 1);
  }, []);
  const handlePrevClick = useCallback(() => {
    setCurrentPage((v) => v - 1);
  }, []);

  const totalPageCount = Math.ceil(data.total_orders / 10) || 0;

  const disablePrevButton = currentPage === 1;
  const disableNextButton = currentPage === totalPageCount || totalOrders === 0;

  const checkFilter = useCallback(
    (status) => {
      if (status === "Hold") {
        setCurrentPage(1);
        setTotalOrders(data.total_on_hold_orders);
      }
      if (status === "Completed") {
        setCurrentPage(1);
        setTotalOrders(data.total_completed_orders);
      }
      if (status === "Cancelled") {
        setCurrentPage(1);
        setTotalOrders(data.total_cancelled_orders);
      }
      if (status === "Preparing") {
        setCurrentPage(1);
        setTotalOrders(data.total_preparing_orders);
      }
    },
    [data]
  );

  useEffect(() => {
    if (data.status === "success") {
      setTotalOrders(data.total_orders);
      setTotalItemsLength(data.all_orders.length);
    }
    checkFilter();
  }, [data, checkFilter]);

  return (
    <>
      <div className="hidden">
        <LogoutReportInvoice
          componentref={componentref}
          data={data}
          orderData={orderData}
        />
      </div>
      <div className="w-full">
        <div className="logout-order-navbar m-[5px] p-2 rounded-[10px] shadow bg-white mb-4">
          <div className="flex justify-between items-center">
            <div>
              <img alt="logo" src={Logo} className="w-12 h-12 cursor-pointer rounded-full" />
            </div>
            <div className="flex gap-2">
              <button
                onClick={handlePrint}
                className="bg-primaryLightColor25 text-primaryColor px-8 py-2 rounded-[10px] font-bold hover:bg-primaryLightColor30 flex"
              >
                <AiOutlinePrinter className="w-6 h-6 mx-1" />
                Download Report
              </button>
              <button
                onClick={LoginRedirect}
                className="bg-primaryLightColor25 text-primaryColor px-8 py-2 rounded-[10px] font-bold hover:bg-primaryLightColor30 flex"
              >
                <LoginIcon className="w-6 h-6" />
                Login
              </button>
            </div>
          </div>
        </div>
        <div className="logout-order-container m-[5px] px-3">
          <div className="grid lg:grid-cols-3 md:grid-cols-3 gap-4 mb-5">
            <div className="bg-finalLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-finalColor relative overflow-hidden shadow shadow-finalLightColor hover:shadow-lg hover:shadow-finalLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="text-finalColor font-bold text-2xl uppercase leading-none mb-2">
                  Total Cash Collection
                </h3>
                <span className="text-black/70 font-bold text-2xl">
                  &#8377;
                </span>
                <CountUp
                  delay={1}
                  decimals={2}
                  end={data.total_cash_collection || "0"}
                  className="text-black/70 font-bold text-2xl"
                />
              </div>
              <div>
                <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-finalColor hover:opacity-100" />
              </div>
            </div>


            <div className="bg-finalLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-finalColor relative overflow-hidden shadow shadow-finalLightColor hover:shadow-lg hover:shadow-finalLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="text-finalColor font-bold text-2xl uppercase leading-none mb-2">
                  Cash from cash sales
                </h3>
                <span className="text-black/70 font-bold text-2xl">
                  &#8377;
                </span>
                <CountUp
                  delay={1}
                  decimals={2}
                  end={data.total_cash_from_cash_sales || "0"}
                  className="text-black/70 font-bold text-2xl"
                />
              </div>
              <div>
                <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-finalColor hover:opacity-100" />
              </div>
            </div>

            <div className="bg-finalLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-finalColor relative overflow-hidden shadow shadow-finalLightColor hover:shadow-lg hover:shadow-finalLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="text-finalColor font-bold text-2xl uppercase leading-none mb-2">
                  Cash from wallet additions
                </h3>
                <span className="text-black/70 font-bold text-2xl">
                  &#8377;
                </span>
                <CountUp
                  delay={1}
                  decimals={2}
                  end={data.total_cash_from_wallet_add || "0"}
                  className="text-black/70 font-bold text-2xl"
                />
              </div>
              <div>
                <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-finalColor hover:opacity-100" />
              </div>
            </div>

            <div className="bg-finalLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-finalColor relative overflow-hidden shadow shadow-finalLightColor hover:shadow-lg hover:shadow-finalLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="text-finalColor font-bold text-2xl uppercase leading-none mb-2">
                  Cash from card + cash
                </h3>
                <span className="text-black/70 font-bold text-2xl">
                  &#8377;
                </span>
                <CountUp
                  delay={1}
                  decimals={2}
                  end={data.total_cash_from_card_Plus_cash || "0"}
                  className="text-black/70 font-bold text-2xl"
                />
              </div>
              <div>
                <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-finalColor hover:opacity-100" />
              </div>
            </div>

            <div className="bg-finalLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-finalColor relative overflow-hidden shadow shadow-finalLightColor hover:shadow-lg hover:shadow-finalLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="text-finalColor font-bold text-2xl uppercase leading-none mb-2">
                  Cash from wallet + cash
                </h3>
                <span className="text-black/70 font-bold text-2xl">
                  &#8377;
                </span>
                <CountUp
                  delay={1}
                  decimals={2}
                  end={data.total_cash_from_wallet_Plus_cash || "0"}
                  className="text-black/70 font-bold text-2xl"
                />
              </div>
              <div>
                <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-finalColor hover:opacity-100" />
              </div>
            </div>

            <div className="bg-finalLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-finalColor relative overflow-hidden shadow shadow-finalLightColor hover:shadow-lg hover:shadow-finalLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="text-finalColor font-bold text-2xl uppercase leading-none mb-2">
                  Petty Cash
                </h3>
                <span className="text-black/70 font-bold text-2xl">
                  &#8377;
                </span>
                <CountUp
                  delay={1}
                  decimals={2}
                  end={data.petty_cash || "0"}
                  className="text-black/70 font-bold text-2xl"
                />
              </div>
              <div>
                <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-finalColor hover:opacity-100" />
              </div>
            </div>


          </div>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-4 mb-5">

            <div className="bg-initLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-initColor relative overflow-hidden shadow shadow-initLightColor hover:shadow-lg hover:shadow-initLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="text-initColor font-bold text-2xl uppercase leading-none mb-2">
                  Total Digital Sales
                </h3>
                <span className="text-black/70 font-bold text-2xl">
                  &#8377;
                </span>
                <CountUp
                  delay={1}
                  decimals={2}
                  end={data.total_digital_collection || "0"}
                  className="text-black/70 font-bold text-2xl"
                />
              </div>
              <div>
                <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-initColor hover:opacity-100" />
              </div>
            </div>

            <div className="bg-initLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-initColor relative overflow-hidden shadow shadow-initLightColor hover:shadow-lg hover:shadow-initLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="text-initColor font-bold text-2xl uppercase leading-none mb-2">
                  Card Sales
                </h3>
                <span className="text-black/70 font-bold text-2xl">
                  &#8377;
                </span>
                <CountUp
                  delay={1}
                  decimals={2}
                  end={data.total_digital_from_card_sales || "0"}
                  className="text-black/70 font-bold text-2xl"
                />
              </div>
              <div>
                <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-initColor hover:opacity-100" />
              </div>
            </div>

            <div className="bg-initLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-initColor relative overflow-hidden shadow shadow-initLightColor hover:shadow-lg hover:shadow-initLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="text-initColor font-bold text-2xl uppercase leading-none mb-2">
                  Card+Cash Sales
                </h3>
                <span className="text-black/70 font-bold text-2xl">
                  &#8377;
                </span>
                <CountUp
                  delay={1}
                  decimals={2}
                  end={data.total_digital_from_card_Plus_cash || "0"}
                  className="text-black/70 font-bold text-2xl"
                />
              </div>
              <div>
                <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-initColor hover:opacity-100" />
              </div>
            </div>

            <div className="bg-initLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-initColor relative overflow-hidden shadow shadow-initLightColor hover:shadow-lg hover:shadow-initLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="text-initColor font-bold text-2xl uppercase leading-none mb-2">
                  Wallet Addition
                </h3>
                <span className="text-black/70 font-bold text-2xl">
                  &#8377;
                </span>
                <CountUp
                  delay={1}
                  decimals={2}
                  end={data.total_digital_from_wallet_add || "0"}
                  className="text-black/70 font-bold text-2xl"
                />
              </div>
              <div>
                <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-initColor hover:opacity-100" />
              </div>
            </div>

            <div className="bg-initLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-initColor relative overflow-hidden shadow shadow-initLightColor hover:shadow-lg hover:shadow-initLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="text-initColor font-bold text-2xl uppercase leading-none mb-2">
                  Wallet+Card
                </h3>
                <span className="text-black/70 font-bold text-2xl">
                  &#8377;
                </span>
                <CountUp
                  delay={1}
                  decimals={2}
                  end={data.total_digital_from_wallet_Plus_card || "0"}
                  className="text-black/70 font-bold text-2xl"
                />
              </div>
              <div>
                <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-initColor hover:opacity-100" />
              </div>
            </div>

          </div>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-4 mb-5">

            <div className="bg-revenueLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-revenueColor relative overflow-hidden shadow shadow-revenueLightColor hover:shadow-lg hover:shadow-revenueLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="text-revenueColor font-bold text-2xl uppercase leading-none mb-2">
                  Total Wallet Collection
                </h3>
                <span className="text-black/70 font-bold text-2xl">
                  &#8377;
                </span>
                <CountUp
                  delay={1}
                  decimals={2}
                  end={data.total_wallet_collection || "0"}
                  className="text-black/70 font-bold text-2xl"
                />
              </div>
              <div>
                <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-revenueColor hover:opacity-100" />
              </div>
            </div>


            <div className="bg-revenueLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-revenueColor relative overflow-hidden shadow shadow-revenueLightColor hover:shadow-lg hover:shadow-revenueLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="text-revenueColor font-bold text-2xl uppercase leading-none mb-2">
                  Total Wallet Spend
                </h3>
                <span className="text-black/70 font-bold text-2xl">
                  &#8377;
                </span>
                <CountUp
                  delay={1}
                  decimals={2}
                  end={data.total_wallet_spnd_collection || "0"}
                  className="text-black/70 font-bold text-2xl"
                />
              </div>
              <div>
                <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-revenueColor hover:opacity-100" />
              </div>
            </div>

            <div className="bg-revenueLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-revenueColor relative overflow-hidden shadow shadow-revenueLightColor hover:shadow-lg hover:shadow-revenueLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="text-revenueColor font-bold text-2xl uppercase leading-none mb-2">
                  Wallet Spend from Wallet Sales
                </h3>
                <span className="text-black/70 font-bold text-2xl">
                  &#8377;
                </span>
                <CountUp
                  delay={1}
                  decimals={2}
                  end={data.total_wallet_spnd_from_wallet_sales || "0"}
                  className="text-black/70 font-bold text-2xl"
                />
              </div>
              <div>
                <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-revenueColor hover:opacity-100" />
              </div>
            </div>


            <div className="bg-revenueLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-revenueColor relative overflow-hidden shadow shadow-revenueLightColor hover:shadow-lg hover:shadow-revenueLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="text-revenueColor font-bold text-2xl uppercase leading-none mb-2">
                  Wallet + Cash Spend
                </h3>
                <span className="text-black/70 font-bold text-2xl">
                  &#8377;
                </span>
                <CountUp
                  delay={1}
                  decimals={2}
                  end={data.total_wallet_spnd_from_wallet_Plus_cash
                    || "0"}
                  className="text-black/70 font-bold text-2xl"
                />
              </div>
              <div>
                <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-revenueColor hover:opacity-100" />
              </div>
            </div>


            <div className="bg-revenueLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-revenueColor relative overflow-hidden shadow shadow-revenueLightColor hover:shadow-lg hover:shadow-revenueLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="text-revenueColor font-bold text-2xl uppercase leading-none mb-2">
                  Card + Wallet Spend
                </h3>
                <span className="text-black/70 font-bold text-2xl">
                  &#8377;
                </span>
                <CountUp
                  delay={1}
                  decimals={2}
                  end={data.total_wallet_spnd_from_card_Plus_wallet || "0"}
                  className="text-black/70 font-bold text-2xl"
                />
              </div>
              <div>
                <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-revenueColor hover:opacity-100" />
              </div>
            </div>


          </div>


          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-4 mb-5">

            <div className="bg-completeLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-completeColor relative overflow-hidden shadow shadow-completeLightColor hover:shadow-lg hover:shadow-completeLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="text-completeColor font-bold text-2xl uppercase leading-none mb-2">
                  Digital Account Sale
                </h3>
                <span className="text-black/70 font-bold text-2xl">
                  &#8377;
                </span>
                <CountUp
                  delay={1}
                  decimals={2}
                  end={data.total_account_sale || "0"}
                  className="text-black/70 font-bold text-2xl"
                />
              </div>
              <div>
                <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-completeColor hover:opacity-100" />
              </div>
            </div>


            <div className="bg-completeLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-completeColor relative overflow-hidden shadow shadow-completeLightColor hover:shadow-lg hover:shadow-completeLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="text-completeColor font-bold text-2xl uppercase leading-none mb-2">
                  Delivery Charge
                </h3>
                <span className="text-black/70 font-bold text-2xl">
                  &#8377;
                </span>
                <CountUp
                  delay={1}
                  decimals={2}
                  end={data.total_account_delivery_charges || "0"}
                  className="text-black/70 font-bold text-2xl"
                />
              </div>
              <div>
                <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-completeColor hover:opacity-100" />
              </div>
            </div>

            <div className="bg-completeLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-completeColor relative overflow-hidden shadow shadow-completeLightColor hover:shadow-lg hover:shadow-completeLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="text-completeColor font-bold text-2xl uppercase leading-none mb-2">
                  Sales as per Delivery
                </h3>
                <span className="text-black/70 font-bold text-2xl">
                  &#8377;
                </span>
                <CountUp
                  delay={1}
                  decimals={2}
                  end={data.sales_per_delievery || "0"}
                  className="text-black/70 font-bold text-2xl"
                />
              </div>
              <div>
                <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-completeColor hover:opacity-100" />
              </div>
            </div>


          </div>

          <div className="grid lg:grid-cols-1 md:grid-cols-1 gap-4 mb-5">

            <div className="bg-onholdLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-onholdColor relative overflow-hidden shadow shadow-onholdLightColor hover:shadow-lg hover:shadow-onholdLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="text-onholdColor font-bold text-2xl uppercase leading-none mb-2">
                  Net Sales
                </h3>
                <span className="text-black/70 font-bold text-2xl">
                  &#8377;
                </span>
                <CountUp
                  delay={1}
                  decimals={2}
                  end={data.netsales || "0"}
                  className="text-black/70 font-bold text-2xl"
                />
              </div>
              <div>
                <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-onholdColor hover:opacity-100" />
              </div>
            </div>


          </div>

          {/* <div className="grid lg:grid-cols-5 md:grid-cols-3 gap-4 mb-5">
            <div className="bg-primaryLightColor25 px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-primaryColor relative overflow-hidden shadow shadow-primaryLightColor25 hover:shadow-lg hover:shadow-primaryLightColor25 hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="text-primaryColor font-bold text-2xl uppercase leading-none mb-2">
                  Total
                  <br />
                  Orders
                </h3>
                <CountUp
                  delay={1}
                  end={data.total_orders || "0"}
                  className="text-black/70 font-bold text-2xl"
                />
              </div>
              <div>
                <DocumentTextIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-primaryColor hover:opacity-100" />
              </div>
            </div>
            <div className="bg-preparingLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-preparingColor relative overflow-hidden shadow shadow-preparingLightColor hover:shadow-lg hover:shadow-preparingLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="text-preparingColor font-bold text-2xl uppercase leading-none mb-2 ">
                  Preparing
                  <br /> Orders
                </h3>
                <CountUp
                  delay={1}
                  end={data.total_preparing_orders || "0"}
                  className="text-black/70 font-bold text-2xl"
                />
              </div>
              <div>
                <DocumentTextIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-preparingColor hover:opacity-100" />
              </div>
            </div>

            <div className="bg-onholdLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-onholdColor relative overflow-hidden shadow shadow-onholdLightColor hover:shadow-lg hover:shadow-onholdLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="text-onholdColor font-bold text-2xl uppercase leading-none mb-2">
                  Onhold
                  <br />
                  Order
                </h3>
                <CountUp
                  delay={1}
                  end={data.total_on_hold_orders || "0"}
                  className="text-black/70 font-bold text-2xl"
                />
              </div>
              <div>
                <DocumentTextIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-onholdColor hover:opacity-100" />
              </div>
            </div>

            <div className="bg-completeLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-completeColor relative overflow-hidden shadow shadow-completeLightColor hover:shadow-lg hover:shadow-completeLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="text-completeColor font-bold text-2xl uppercase leading-none mb-2">
                  Completed
                  <br />
                  Orders
                </h3>
                <CountUp
                  delay={1}
                  end={data.total_completed_orders || "0"}
                  className="text-black/70 font-bold text-2xl"
                />
              </div>
              <div>
                <DocumentTextIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-completeColor hover:opacity-100" />
              </div>
            </div>

            <div className="bg-cancelledLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-cancelledColor relative overflow-hidden shadow shadow-cancelledLightColor hover:shadow-lg hover:shadow-cancelledLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="text-cancelledColor font-bold text-2xl uppercase leading-none mb-2">
                  Cancelled
                  <br />
                  Orders
                </h3>
                <CountUp
                  delay={1}
                  end={data.total_cancelled_orders || "0"}
                  className="text-black/70 font-bold text-2xl"
                />
              </div>
              <div>
                <DocumentTextIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-cancelledColor hover:opacity-100" />
              </div>
            </div>
          </div> */}

          {data.status === "success" ? (
            {/*             <>
              <div className="filter-button flex items-center justify-center gap-2 flex-wrap bg-white p-2 rounded-[10px] mb-3">
                <button
                  className="bg-primaryLightColor30 text-primaryColor px-4 py-2 rounded-[10px] font-bold hover:bg-primaryColor hover:text-white flex"
                  onClick={() => {
                    setOrderData(data.all_orders);
                    setTotalOrders(data.total_orders);
                    setTotalItemsLength(data.all_orders.length);
                  }}
                >
                  All
                </button>
                <button
                  className="bg-preparingLightColor text-preparingColor  px-4 py-2 rounded-[10px] font-bold hover:bg-preparingColor hover:text-white flex"
                  onClick={() => {
                    filterItem("Preparing");
                  }}
                >
                  Preparing
                </button>
                <button
                  className="bg-onholdLightColor text-onholdColor px-4 py-2 rounded-[10px] font-bold hover:bg-onholdColor hover:text-white flex"
                  onClick={() => {
                    filterItem("Hold");
                  }}
                >
                  Onhold
                </button>
                <button
                  className="bg-completeLightColor text-completeColor px-4 py-2 rounded-[10px] font-bold hover:bg-completeColor hover:text-white flex"
                  onClick={() => {
                    filterItem("Completed");
                  }}
                >
                  Completed
                </button>
                <button
                  className="bg-cancelledLightColor text-cancelledColor px-4 py-2 rounded-[10px] font-bold hover:bg-cancelledColor hover:text-white flex"
                  onClick={() => {
                    filterItem("Cancelled");
                  }}
                >
                  Cancelled
                </button>
              </div>

              {loading ? (
                <div className="orders-div">
                  {orderData.length === 0 ? (
                    <div className="flex justify-center items-center h-[200px] font-bold text-lg text-gray-900">
                      No Order Found
                    </div>
                  ) : (
                    <>
                      {orderData &&
                        orderData?.map((order, i) => {
                          return <LogoutEmpOrderItem key={i} order={order} />;
                        })}
                      <div className="flex justify-between items-center">
                        <h6 className="font-bold text-mutedColor ml-1">
                          {totalItemsLength} of {totalOrders}
                        </h6>
                        <div className="flex items-center">
                          <button
                            className="button-pimary-light p-2 bg-primaryLightColor25 text-primaryColor rounded-l-[10px]"
                            onClick={handlePrevClick}
                            disabled={disablePrevButton}
                          >
                            <ChevronLeftIcon className="h-6 w-6" />
                          </button>
                          <button className="button-pimary-light p-2 bg-primaryLightColor25 text-primaryColor font-bold cursor-default">
                            Page {currentPage}
                          </button>
                          <button
                            className="button-pimary-light p-2 bg-primaryLightColor25 text-primaryColor rounded-r-[10px]"
                            onClick={handleNextClick}
                            disabled={disableNextButton}
                          >
                            <ChevronRightIcon className="h-6 w-6" />
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <LoaderSpinner />
              )}
            </> */}
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default LogoutEmployeeOrders2;
