import React from "react";
import Moment from "react-moment";
import { useState } from "react";
import { useRef } from "react";
import { forwardRef } from "react";
import "moment-timezone";
import { MenuAlt2Icon } from "@heroicons/react/outline";
import { useReactToPrint } from "react-to-print";
import Notification from "../layout/Notification.js";
import ProductsCard from "./ProductsCard.js";
import Sidebar from "../layout/Sidebar.js";
import Loader from "./Loader.js";
import Nodata from "./Nodata.js";
import BillOrderCartItem from "./BillOrderCartItem.js";
const OrderDetail = ({ Order }) => {
  const order = Order?.data;
  let orderTime = 0;
  order?.order_items.forEach((item) => {
    let time = Number(item.prepare_time?.split('m')[0]);
    orderTime += time;
  })
  let statusClass = "";
  switch (order?.status) {
    case "Preparing": statusClass = "text-sky-500"; break;
    case "Cancelled": statusClass = "text-red-500"; break;
    case "Completed": statusClass = "text-green-500"; break;
    default: statusClass = "";
  }
  const [isOpenMainSidebar, setIsOpenMainSidebar] = useState(false);
  const componentref = useRef();
  const ToggleMainSidebar = () => {
    setIsOpenMainSidebar(!isOpenMainSidebar);
  };

  const handlePrint = useReactToPrint({
    content: () => componentref.current,
  });

  const OrderBill = forwardRef((props, ref) => {
    return (
      <>
        <div className="flex flex-col w-[20rem] overflow-hidden bg-white px-2">
          <BillOrderCartItem order={order} componentref={ref} />
        </div>
      </>
    );
  });

  if (!order) {
    return <Nodata />;
  }
  return (
    <div>
      <div className="flex justify-between items-center bg-stone-50 borderRadius rounded-[10px] p-1 m-2">
        <div
          className="p-2 bg-primaryLightColor25 rounded-[10px] text-primaryColor cursor-pointer"
          onClick={ToggleMainSidebar}
        >
          <MenuAlt2Icon className="w-6 h-6" />
        </div>

        <h1 className="font-bold text-2xl text-[#999999]">
          ORDER:<span className="text-black"> {order.order_id}</span>
        </h1>

        <Notification />
      </div>

      <div className="h-[calc(100vh_-_80px)] overflow-auto">
        <Sidebar
          isOpenMainSidebar={isOpenMainSidebar}
          setIsOpenMainSidebar={setIsOpenMainSidebar}
          width="100%"
          height="100%"
        />
        <div className="order-details-div-2 p-2 m-2 flex flex-wrap flex-col md:flex-row  md:justify-between md:items-center text-[#17202A]  bg-stone-50 rounded-[10px]">
          <div>
            <h1 className="mb-0 text-3xl text-[#17202A] font-bold">
              ₹{" "}
              {order.status === "Cancelled"
                ? Math.round(order?.sub_total * 100) / 100
                : Math.round(order.paid_price * 100) / 100}
            </h1>
          </div>
          <div>
            <div className="flex md:items-end justify-center flex-col">
              <h6 className={`mb-1 font-bold ${statusClass}`}>
                <span className={`text-gray-500`}>Status :</span> {order.status}
              </h6>
            </div>
            <div className="flex md:items-end justify-center flex-col">
              <h6 className={`mb-1 font-bold`}>
                <span className="text-gray-500">Order Date :</span>{" "}
                <Moment format="MMM D, YYYY hh:mm a">{order.createdAt}</Moment>
              </h6>
            </div>
            <div className="flex md:items-end justify-center flex-col">
              <h6 className={`mb-1 font-bold`}>
                <span className="text-gray-500">Estimated Time :</span> {orderTime} min
              </h6>
            </div>
          </div>
        </div>
        <div className="flex m-2 gap-2">
          <div className="bg-stone-50 w-[50%] rounded-[10px] order-details-div-2 p-2 pb-0 text-sm">
            <h6 className="w-fit font-bold uppercase mr-2">Name</h6>
            <p className="mb-0 font-bold text-gray-500">{Order.customer_name}</p>
          </div>
          <div className="bg-stone-50 w-[50%] rounded-[10px] order-details-div-2 p-2 text-sm text-right">
            <h6 className="font-bold uppercase mr-2">Address</h6>
            <p className="mb-0 font-bold text-gray-500">{order.address}</p>
          </div>
        </div>
        <div className="flex justify-between items-center space-x-2 m-2 ">
          <div className="bg-stone-50 rounded-[10px] w-[50%] h-fit">
            <div className="order-details-div-2 p-2 text-sm">
              <h6 className="font-bold mb-2 uppercase h-fit">Payment Method</h6>
              <div className="flex justify-between align-items-center">
                <p className="mb-0 font-bold text-gray-500">
                  {order.payment_method?.toUpperCase() || "N/A"}
                </p>
                <p className="mb-0 font-bold text-gray-500 ">
                  ₹ {order.paid_price}
                </p>
              </div>
            </div>
          </div>
          <div className="bg-stone-50 rounded-[10px] w-[50%] flex h-fit">
            <div className="order-details-div-2 p-2 text-sm w-[50%] ">
              <h6 className="font-bold mb-2 uppercase ">Order Type</h6>
              <p className="mb-0 font-bold text-gray-500">
                {order.order_type || "N/A"}
              </p>
            </div>
            <div className="order-details-div-2 p-2 text-sm w-[50%] text-right">
              <h6 className="font-bold mb-2 uppercase ">Delivery Charges</h6>
              <p className="mb-0 font-bold text-gray-500">
                {order.order_type === "Delivery" ? `₹ ${order.delivery_charges}` : "N/A"}
              </p>
            </div>
          </div>
        </div>

        <div className="list-of-products   ">
          <h6 className="font-bold mx-2 uppercase text-[#17202A]">
            Order Items
          </h6>
          <ul className="p-2 w-full max-h-[340px] overflow-y-auto">
            <ProductsCard order_items={order.order_items} />
          </ul>
        </div>

        <div className="bg-stone-50 p-2 mx-2  rounded-[10px] ">
          <div className="flex justify-between items-center  border-gray-200 p-1">
            <h6 className=" text-sm text-gray-700">Subtotal</h6>

            <h6 className="font-bold text-sm text-gray-500">
              ₹ {Math.round(order?.sub_total * 100) / 100 || 0}
            </h6>
          </div>
          <div className="flex justify-between items-center border-b border-gray-200 p-1">
            <h6 className=" text-sm text-gray-700">Discount</h6>
            <h6 className="text-gray-500 font-bold cursor-pointer">
              ₹ {Math.round(order?.discount * 100) / 100 || 0}
            </h6>
          </div>
          <div className="flex justify-between items-center border-b border-gray-200 p-1">
            <h6 className=" text-sm text-gray-700">Membership Discount</h6>
            <h6 className="text-gray-500 font-bold cursor-pointer">
              ₹ {Math.round(order?.membership_discount * 100) / 100 || 0}
            </h6>
          </div>
          <div className="flex justify-between items-center border-b border-gray-200 p-1">
            <h6 className=" text-sm text-gray-700">SGST</h6>
            <h6 className="font-bold text-sm text-gray-500">
              ₹ {Math.round(order?.sgst * 100) / 100 || 0}
            </h6>
          </div>
          <div className="flex justify-between items-center border-b border-gray-200 p-1">
            <h6 className=" text-sm text-gray-700">CGST</h6>
            <h6 className="font-bold text-sm text-gray-500">
              ₹ {Math.round(order?.cgst * 100) / 100 || 0}
            </h6>
          </div>
          <div className="flex justify-between items-center border-b border-gray-200 p-1">
            <h6 className=" text-md font-bold text-gray-700">Total</h6>
            <h6 className="font-bold text-md text-gray-700">
              ₹ {Math.round(order?.paid_price * 100) / 100 || 0}
            </h6>
          </div>
          {order.payment_method === "card + cash" && (
            <div className="flex justify-between items-center border-b border-gray-200 p-1">
              <h6 className=" text-sm text-gray-700">Cash Amount</h6>
              <h6 className="font-bold text-sm text-gray-500">
                ₹ {Math.round(order.cash_amount * 100) / 100}
              </h6>
            </div>
          )}
          <div className="flex justify-between items-center border-b border-gray-200 p-1">
            <h6 className=" text-sm text-gray-700">
              {order.payment_method === "cash"
                ? "Change"
                : order.payment_method === "wallet"
                  ? "Wallet Amount"
                  : "Card Amount"}
            </h6>
            <h6 className="font-bold text-sm text-gray-500">
              ₹{" "}
              {order.payment_method === "cash"
                ? order.change
                : order.card_amount}
            </h6>
          </div>

          <div className="row  g-2 flex justify-center ">
            <div>
              <button
                className=" my-5 bg-primaryColor text-white text-center w-[350px] uppercase font-bold rounded-[10px] p-2"
                onClick={handlePrint}
              >
                Print Invoice
              </button>
            </div>
          </div>
          <Loader />
        </div>
        <div className="hidden">
          <OrderBill subTotal={order.sub_total} ref={componentref} />
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
