import React, { Fragment, useRef, useCallback, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import orders from "../../api/orders";
import { getCustomerInfo } from "../../actions/customerInfoAction";
import { get } from "lodash";
import store from "../../store";
import AddMoneyOtpModal from "./AddMoneyOtpModal";
import AddMoneyWarningModel from "./AddMoneyWarningModel";
import paymentMethodImage from "../../assets/images/payment.png"

export default function AddMoney({
  isMoneyOpen,
  setIsMoneyOpen,
  gettingActiveCustomerInfo,
  customerInfoRenderData,
  setIsCheckoutOpen
}) {
  // console.log(customerInfoRenderData);
  const alert = useAlert();
  const inputRef = useRef(null);
  useEffect(() => {
    if (isMoneyOpen && inputRef.current !== null) inputRef.current.focus();
  }, [isMoneyOpen, inputRef.current])
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [disableAddMoneyButton, setDisableAddMoneyButton] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [amt, setAmt] = useState('');
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  // const [disableVerifyButton, setDisableVerifyButton] = useState(true);
  // const [isOpenOtpModal, setIsOpenOtpModal] = useState(false);
  // const [OtpResData, setOtpResData] = useState({ status: null });
  // const [addMonResData, setAddMonResData] = useState({ status: null });
  // const [counter, setCounter] = React.useState(59);
  // const resetTimer = useCallback(() => {
  //   setCounter(59);
  // }, [setCounter]);

  let closeButtonRef = useRef(null);
  function closeMoneyModal() {
    setIsMoneyOpen(false);
    setDisableAddMoneyButton(true);
    setAmt('');
    setPaymentMethod("cash");
    setIsAccepted(false);
    if (setIsCheckoutOpen !== undefined) setIsCheckoutOpen(true);
  }

  const customerInfoRender = null;

  // const OtpSend = () => {
  //   const token = get(store.getState(), "userLogin.userInfo.token");
  //   const config = {
  //     headers: { "Content-Type": "application/json", token: token },
  //   };
  //   orders
  //     .post(
  //       "/send_otp_memb_cust",
  //       {
  //         mobile_no:
  //           customerInfoRender[0]?.mobile_no || customerInfoRender.mobile_no,
  //       },
  //       config
  //     )
  //     .then((res) => {
  //       if (res.data === "Token does not match") {
  //         sessionStorage.clear();
  //         window.location.href = "/login";
  //       }

  //       // console.log(res.data);
  //       if (res.data.status === "Success") {
  //         return;
  //       }
  //       if (res.data.status === "failure") {
  //         return;
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const addMoney = () => {
    const customer = customerInfoRenderData;
    const token = state.userLogin.userInfo.token;
    const user = state.userDetails.user;
    const amount = Number(amt);
    if (amount <= 0 || amount === "") alert.error("Enter valid amount !");
    else {
      if (isAccepted === false) {
        setIsMoneyOpen(false);
        setIsWarningOpen(true);
      }
    }
  };

  useEffect(() => {
    if (isAccepted) {
      const customer = customerInfoRenderData;
      const token = state.userLogin.userInfo.token;
      const user = state.userDetails.user;
      const amount = Number(amt);
      let body = {
        customer_no: customer.mobile_no,
        customer_id: customer.customer_id,
        employee_id: user.employee_id,
        branch_id: user.branch_id,
        branch_name: user.branch,
        amount: amount,
        comment: null,
        status: "Paid",
        paid: true,
        payment_method: paymentMethod,
        payment_id: "",
        account_id: "",
      };
      orders
        .post("/add_cust_wallet_money", body, {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            // setAddMonResData(res.data);
            // gettingActiveCustomerInfo();
            dispatch(getCustomerInfo(customer.mobile_no));
            gettingActiveCustomerInfo();
            alert.success(res.data.msg);
            // setAddMonResData({ status: null });
            // setOtpResData({ status: null });
            closeMoneyModal();
          } else {
            alert.error(res.data.msg);
            closeMoneyModal();
          }
        })
        .catch((err) => console.log(err));
    }
  }, [isAccepted]);
  // console.log(OtpResData.status === "success", "bhai");
  useEffect(() => {
    if (Number(amt) > 0 && Number(amt) !== "") {
      setDisableAddMoneyButton(false);
    } else {
      setDisableAddMoneyButton(true);
    }
    // if (addMonResData.status === null) {
    //   setDisableAddMoneyButton(true);
    // }
    // if (OtpResData.status === "success") {
    //   setDisableAddMoneyButton(false);
    //   setDisableVerifyButton(true);
    // }
    // if (OtpResData.status === "success" && addMonResData.status === "success") {
    //   setDisableAddMoneyButton(true);
    //   setDisableVerifyButton(true);
    // }
  }, [amt, disableAddMoneyButton]);


  //console.log(disableVerifyButton,"verifybutton");
  //console.log(disableAddMoneyButton,"addmoneybutton")

  return (
    <>
      <Transition appear show={isMoneyOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 "
          style={{ zIndex: "67" }}
          initialFocus={closeButtonRef}
          onClose={() => setIsMoneyOpen(true)}
        >
          <div ref={closeButtonRef} className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black/20 backdrop-blur-[3px] w-full " />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="w-[90%] max-w-[400px] p-6 overflow-hidden align-middle transition-all transform text-center bg-white shadow-xl rounded-2xl absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-55%]">
                <Dialog.Title className="text-center text-2xl uppercase font-bold mb-4 text-gray-700">
                  Add Credit
                </Dialog.Title>
                <input
                  type="number"
                  className="m-auto border-2 p-2 rounded-lg w-full"
                  placeholder="Enter Amount in ₹"
                  value={amt}
                  onChange={(e) => setAmt(e.target.value)}
                  name=""
                  id=""
                  ref={inputRef}
                />

                <div className="text-lg mt-4 font-semibold">
                  Select the mode of payment
                </div>
                <div className="flex justify-center">
                  <img className="w-2/3" src={paymentMethodImage} alt="payment method image" />
                </div>
                <div className="flex flex-wrap justify-center items-center w-full text-left pt-4">
                  <div className="mx-4 my-1">
                    <input
                      type="radio"
                      className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-[#664d03] checked:border-[#FFF] focus:outline-none transition duration-200 mr-1 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
                      name="paymentMethod"
                      id=""
                      value="cash"
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      checked={paymentMethod === "cash" ? true : false}
                    />{" "}
                    Cash
                  </div>
                  <div className="mx-4 my-1">
                    <input
                      type="radio"
                      className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-[#664d03] checked:border-[#FFF] focus:outline-none transition duration-200 mr-1 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
                      name="paymentMethod"
                      id=""
                      value="card"
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      checked={paymentMethod === "card" ? true : false}
                    />{" "}
                    Card
                  </div>
                  {/* <div className="mx-4 my-1">
                    <input
                      type="radio"
                      className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-[#664d03] checked:border-[#FFF] focus:outline-none transition duration-200 mr-1 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
                      name="paymentMethod"
                      id=""
                      value="upi"
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      checked={paymentMethod === "upi" ? true : false}
                    />{" "}
                    UPI
                  </div> */}
                </div>
                <div className="flex justify-center mt-4 gap-2">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-bold uppercase text-slate-500 bg-slate-100 border border-transparent rounded-[10px] hover:bg-slate-200 md:w-1/3 w-1/3"
                    onClick={closeMoneyModal}
                  >
                    Close
                  </button>
                  {/* <button
                    style={
                      disableVerifyButton
                        ? { background: " #cccccc" }
                        : { background: "#2d3748" }
                    }
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-bold uppercase text-white bg-gray-800 border border-transparent rounded-[10px] hover:bg-gray-900 w-1/3 "
                    onClick={() => {
                      setIsOpenOtpModal(true);
                      OtpSend();
                      resetTimer();
                      closeMoneyModal();
                    }}
                    disabled={disableVerifyButton}
                  >
                    Verify Otp
                  </button> */}

                  <button
                    style={
                      disableAddMoneyButton
                        ? { background: "rgba(102, 77, 3, .3)" }
                        : { background: "#664d03" }
                    }
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-bold uppercase text-white border border-transparent rounded-[10px] hover:bg-primaryDarkColor md:w-2/3 w-2/3"
                    onClick={addMoney}
                    disabled={disableAddMoneyButton}
                  >
                    Add Credit
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      <AddMoneyWarningModel
        isWarningOpen={isWarningOpen}
        setIsMoneyOpen={setIsMoneyOpen}
        amount={amt}
        setIsWarningOpen={setIsWarningOpen}
        setIsAccepted={setIsAccepted}
        mobile={customerInfoRenderData.mobile_no} />
      {/* <AddMoneyOtpModal
        isOpenOtpModal={isOpenOtpModal}
        setIsOpenOtpModal={setIsOpenOtpModal}
        OtpSend={OtpSend}
        counter={counter}
        resetTimer={resetTimer}
        setCounter={setCounter}
        setOtpResData={setOtpResData}
        setIsMoneyOpen={setIsMoneyOpen}
        customerInfoRender={
          customerInfoRender !== null &&
          (customerInfoRender[0] ? customerInfoRender[0] : customerInfoRender)
        }
        customerInfoRenderData={customerInfoRenderData}
      /> */}
    </>
  );
}
