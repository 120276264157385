import axios from "axios";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import Notification from "../layout/Notification";
import Sidebar from "../layout/Sidebar";
import OnHoldItemsCard from "./OnHoldItemsCard";
import CancelModal from "./CancelModal";
import Loader from "../home/Loader";
import { connect, useDispatch, useSelector } from "react-redux";
import { showLoader, hideLoader } from "../../actions/orderListAction";
import { MenuAlt2Icon } from "@heroicons/react/outline";
import store from "../../store";
import { get } from "lodash";
import OnHoldCheckoutModal from "./OnHoldCheckoutModal";
import { useNavigate } from "react-router-dom";
import { removeAllItemsFromCart } from "../../actions/cartAction";
import { useAppContext } from "../../context/AppContext";

const getSingleOnHoldOrder = async (orderId) => {
  const url = `${process.env.REACT_APP_BASE_URL}fetch_single_order?order=${orderId}`;

  const token = get(store.getState(), "userLogin.userInfo.token");
  const config = {
    headers: {
      token: token,
    },
  };
  try {
    const response = await axios.get(url, config);
    if (response.data.data === "Token does not match") {
      sessionStorage.clear();
      window.location.href = "/login";
    }
    return response.data.data;
  } catch (error) { }
};

const OnHoldOrderDetailsDisplay = (props) => {
  const { setDuration } = useAppContext();
  const dispatch = useDispatch();
  const { orderId, showLoader, hideLoader } = props;
  const [isOpenMainSidebar, setIsOpenMainSidebar] = useState(false);
  const [singleOnHoldOrder, setSingleOnHoldOrder] = useState({});
  const [done, setDone] = useState(undefined);
  const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);
  const [isopencancel, setIsOnCancelOpen] = useState(false);

  const navigate = useNavigate();
  const { cartItems } = useSelector((state) => state.cart);
  let modaldata;

  useEffect(() => {
    showLoader();
    setTimeout(() => {
      hideLoader();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const ToggleMainSidebar = () => {
    setIsOpenMainSidebar(!isOpenMainSidebar);
  };
  const onEditHandler = () => {
    if (cartItems.length !== 0) {
      dispatch(removeAllItemsFromCart());
      navigate(`/onhold-orders/${orderId}`);
    } else {
      navigate(`/onhold-orders/${orderId}`);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (orderId) {
        getSingleOnHoldOrder(orderId).then((data) => {
          setSingleOnHoldOrder(data);
          setDuration(data?.ord_rec_time);
          setDone(true);
        });
      }
    }, 1000);
    setDone(undefined);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, setDuration]);
  modaldata = singleOnHoldOrder;
  console.log(singleOnHoldOrder);

  return (
    <>
      {!done ? (
        <div className="right-section w-full  h-[calc(100%_-_50px)] relative bg-stone-200">
          <div></div>
          <Loader />
        </div>
      ) : (
        <section className="right-section w-full  h-[calc(100%_-_50px)] relative bg-stone-200">
          <div className="flex justify-between items-center bg-stone-50 borderRadius rounded-[10px] p-1 m-2">
            <div
              className="p-2 bg-primaryLightColor25 rounded-[10px] text-primaryColor cursor-pointer"
              onClick={ToggleMainSidebar}
            >
              <MenuAlt2Icon className="w-6 h-6" />
            </div>

            <h1 className="font-bold text-2xl text-[#999999]">
              ORDER:
              <span className="text-[#17202A]">
                {" "}
                {singleOnHoldOrder.order_id}
              </span>
            </h1>

            <Notification />
          </div>

          <div className="h-[calc(100vh_-_80px)] overflow-auto">
            <Sidebar
              isOpenMainSidebar={isOpenMainSidebar}
              setIsOpenMainSidebar={setIsOpenMainSidebar}
              width="100%"
              height="100%"
            />
            <div className="order-details-div-2 p-2 m-2 flex flex-wrap justify-between items-center text-[#17202A] bg-stone-50 rounded-[10px]">
              <div>
                {singleOnHoldOrder.sub_total && (
                  <h1 className="mb-0 text-3xl text-[#17202A] font-bold">
                    ₹{" "}
                    {Math.round(
                      (singleOnHoldOrder.sub_total +
                        singleOnHoldOrder.tax +
                        singleOnHoldOrder.discount) *
                      100
                    ) / 100}
                  </h1>
                )}
              </div>
              <div className="text-sm">
                <div className="flex items-end justify-center flex-col">
                  <h6 className="mb-1 font-bold text-[#17202A] ">
                    <span className="text-gray-500">Status:</span>{" "}
                    <span className="text-onholdColor">{singleOnHoldOrder.status}</span>
                  </h6>
                </div>

                <h6 className="mb-1 font-bold text-[#17202A]">
                  <span className="text-gray-500">Order Date: </span>
                  <Moment format="MMM D, YYYY hh:mm a">
                    {singleOnHoldOrder.updatedAt}
                  </Moment>
                </h6>

                {/* <div className="flex items-end justify-center flex-col">
                  <h6 className="mb-1 font-bold text-[#17202A]">
                    <span className="text-gray-500">Served by: </span>
                    Shb1
                  </h6>
                </div> */}
              </div>
            </div>

            <div className="h-fit flex gap-2 m-2">
              <div className="order-details-div-2 p-2 w-[50%] text-sm bg-stone-50 rounded-[10px]">
                <h6 className="font-bold mb-2 uppercase h-fit">Name</h6>
                <p className="mb-0 font-bold text-gray-500">
                  {singleOnHoldOrder.customer_name}
                </p>
              </div>
              <div className="order-details-div-2 p-2 w-[50%] text-sm text-right bg-stone-50 rounded-[10px]">
                <h6 className="font-bold mb-2 uppercase h-fit">Address</h6>
                <p className="mb-0 font-bold text-gray-500">
                  {singleOnHoldOrder.address}
                </p>
              </div>
            </div>

            <div className="list-of-products ">
              <h6 className="font-bold mx-2 uppercase text-[#17202A]">
                Order Items
              </h6>
              <ul className="p-2 w-full max-h-[340px] overflow-y-auto text-sm">
                <OnHoldItemsCard order_items={singleOnHoldOrder.order_items} />
              </ul>
            </div>

            <div className="bg-stone-50 p-2 mx-2  rounded-[10px] ">
              <div className="flex justify-between items-center  border-gray-200 p-1">
                <h6 className=" text-xl font-bold text-gray-700">Subtotal</h6>

                <h6 className="font-bold text-xl text-gray-500">
                  ₹ {Math.round(singleOnHoldOrder.sub_total * 100) / 100}
                </h6>
              </div>

              {/* //Place Order Button// */}
              <div className="row gap-2 flex justify-center ">
                <button
                  type="button"
                  className="my-2 font-bold uppercase text-white bg-red-500 border border-transparent  grow w-1/5  rounded-[10px] hover:bg-gray-900 p-2"
                  onClick={() => {
                    setIsOnCancelOpen(true);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="my-2 bg-primaryLightColor25 hover:bg-primaryDarkColor/30 grow text-primaryColor text-center w-2/5 uppercase font-bold rounded-[10px] p-2"
                  onClick={onEditHandler}
                >
                  Edit Order
                </button>
                <button
                  className="my-2 bg-primaryColor text-white text-center grow uppercase w-2/5 font-bold rounded-[10px] p-2"
                  onClick={() => {
                    setIsCheckoutOpen(!isCheckoutOpen);
                  }}
                >
                  Place Order
                </button>
              </div>
              <Loader />
            </div>
          </div>
          <OnHoldCheckoutModal
            isOnholdCheckoutOpen={isCheckoutOpen}
            setIsOnholdCheckoutOpen={setIsCheckoutOpen}
            modaldata={modaldata}
          />
          <CancelModal
            isopencancel={isopencancel}
            setIsOnCancelOpen={setIsOnCancelOpen}
            orderId={singleOnHoldOrder.order_id}
          />
        </section>
      )}
    </>
  );
};

export default connect(null, { showLoader, hideLoader })(
  OnHoldOrderDetailsDisplay
);
