import React, { useState } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import orders from "../../api/orders";

const OnHoldOrdersList = (props) => {
  const { onHoldOrder, setOrderId } = props;
  const state = useSelector(state => state);

  const [name, setName] = useState("");
  // console.log(onHoldOrder);
  // orders.get(`/customer_search?mobile_no=${onHoldOrder?.customer_no}`, {
  //   headers: {
  //     token: state.userLogin.userInfo.token
  //   }
  // }).then(response => {
  //   if (response.data.data !== undefined && response.data.data[0] !== undefined) {
  //     setName(`${response.data.data[0].first_name} ${response.data.data[0].last_name}`);
  //   }
  // })

  function handleSetOrderId() {
    setOrderId(onHoldOrder.order_id);
  }

  return (
    <div
      className="relative flex flex-col min-w-0 rounded-[10px] break-words bg-stone-50 border-closedColor cursor-pointer  p-2 m-2 border-l-4 border-l-orange-600"
      onClick={handleSetOrderId}
    >
      {/* <div className="flex justify-between items-center ">
        <div className="">
          <h6 className="font-bold mb-0">{onHoldOrder.order_id}</h6>
          <p className="font-bold mb-0 text-sm text-gray-500">
            {onHoldOrder?.customer_no || "Guest"}
          </p>
        </div>
        <div className=" flex items-end justify-center flex-col">
          <h6 className="font-bold mb-0">
            ₹{Math.round((onHoldOrder.sub_total + onHoldOrder.tax + onHoldOrder.discount)* 100) / 100}
          </h6>
          <p className="font-bold mb-0 small  text-gray-500">
            <Moment format="MMM D, YYYY hh:mm a">
              {onHoldOrder.updatedAt}
            </Moment>
          </p>
        </div>
      </div> */}
      <div className="flex justify-between font-bold mb-0">
        <h6>{onHoldOrder.order_id}</h6>
        <h6>₹{Math.round((onHoldOrder.sub_total + onHoldOrder.tax + onHoldOrder.discount) * 100) / 100}</h6>
      </div>
      <div className="flex justify-between font-bold mb-0 text-sm text-gray-500">
        <p>{onHoldOrder?.customer_name}</p>
        <p>{onHoldOrder?.customer_no || ""}</p>
        <p>
          <Moment format="MMM D, YYYY hh:mm a">{onHoldOrder.updatedAt}</Moment>
        </p>
      </div>
    </div>
  );
};

export default OnHoldOrdersList;
