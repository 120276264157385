/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import {
  TrashIcon,
  UserAddIcon,
  UserCircleIcon,
  DocumentTextIcon,
  PlusIcon,
} from "@heroicons/react/outline";
import UserSiderbar from "../../components/home/UserSiderbar";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { removeAllItemsFromCart } from "../../actions/cartAction";
import emptyCart from "../../assets/images/empty_cart.svg";
import CheckoutModal from "../../components/home/CheckoutModal";
import HoldModal from "../../components/home/HoldModal";
import OrderItemsSidebar from "../../components/home/OrderItemsSidebar";
import OrderHistorySidebar from "../../components/home/OrderHistorySidebar";
import { useAlert } from "react-alert";
import RightDivCartItem from "../../components/home/RightDivCartItem";
import { getLastCustomerOrderAction } from "../../actions/getLastCustomerOrderAction";
import ConfirmationModalHome from "../../components/home/ConfirmationModalHome";
import useTimer from "../../hooks/useTimer";
import { useAppContext } from "../../context/AppContext";

import orders from "../../api/orders";
import { get } from "lodash";
import store from "../../store";
import AddMoney from "../../components/home/AddMoney";
import { IoWalletOutline } from "react-icons/io5";

const RightSectionHome = () => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);
  const [isOnHoldOpen, setIsOnHoldOpen] = useState(false);
  const [isMoneyOpen, setIsMoneyOpen] = useState(false);
  const [activeCustomerData, setActiveCustomerData] = useState({});
  const [cartItems, setCartItems] = useState([]);

  // STATES FOR CHECKOUTMODAL

  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [orderType, setOrderType] = useState("Dine In");
  const [paymentChecked, setPaymentChecked] = useState(true);
  const [orderTypeChecked, setOrderTypeChecked] = useState(true);

  const { start, stop, reset, duration } = useTimer();

  const { startFunction, setStartFunction } = useAppContext();

  const [isOpenUserSidebar, setIsOpenUserSidebar] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [isOpen, setIsOpen] = useState(false);

  const [customerInfoRenderData, setCustomerInfoRenderData] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [discount, setDiscount] = useState(null);

  //console.log(customerInfoRenderData)

  const discountOnly = discount;

  const hasApplyDiscount = !!discount;

  const ToggleUserSidebar = () => {
    isOpenUserSidebar === true
      ? setIsOpenUserSidebar(false)
      : setIsOpenUserSidebar(true);
  };

  const [isOpenOrderItemsSidebar, setIsOpenOrderItemsSidebar] = useState(false);
  const [isOpenOrderHistorySidebar, setIsOpenOrderHistorySidebar] =
    useState(false);

  const state = useSelector((state) => state);
  const [renderagaiankey, setRenderKey] = useState(0);
  useEffect(() => {
    setCartItems(state.cart.cartItems)
    setRenderKey(i => i + 1);
  }, [state.cart])

  // const deleteAllCartItems = () => {
  //   dispatch(removeAllItemsFromCart());
  //   alert.success("All items removed from cart");
  // };

  const customerInfoRender = JSON.parse(sessionStorage.getItem("customerInfo"));

  useEffect(() => {
    const customerInfoRender = JSON.parse(
      sessionStorage.getItem("customerInfo")
    );
    if (!customerInfoRender) {
      return;
    }
    setCustomerInfoRenderData(customerInfoRender);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [number10] = useState(10);
  const [number20] = useState(20);
  const [number50] = useState(50);
  const [number100] = useState(100);
  const [number200] = useState(200);
  const [number500] = useState(500);
  const [number2000] = useState(2000);

  const [resetAmount] = useState(0);
  const [totalReceived, setTotalReceived] = useState(0);

  // const subTotalAmount =
  //   Math.round(
  //     cartItems.reduce(
  //       (acc, item) =>
  //         acc +
  //         item.qty *
  //         (item.price +
  //           Object.values(item?.add_on_price || {}).reduce(
  //             (a, b) => a + b,
  //             0
  //           )),
  //       0
  //     ) * 100
  //   ) / 100;
  let subTotalAmount = 0;
  cartItems.forEach(
    (item) =>
      (subTotalAmount += Number(item.price + item.add_on_price) * item.qty)
  );
  const totalAmount =
    Math.round(
      (cartItems.reduce(
        (acc, item) =>
          acc +
          item.qty *
          (item.price +
            Object.values(item?.add_on_price || {}).reduce(
              (a, b) => a + b,
              0
            )),
        0
      ) +
        cartItems.reduce(
          (acc, item) =>
            acc +
            item.qty *
            0.05 *
            (item.price +
              Object.values(item?.add_on_price || {}).reduce(
                (a, b) => a + b,
                0
              )),
          0
        )) *
      100
    ) / 100;
  const taxAmount =
    Math.round(
      cartItems.reduce(
        (acc, item) =>
          acc +
          item.qty *
          0.05 *
          (item.price +
            Object.values(item?.add_on_price || {}).reduce(
              (a, b) => a + b,
              0
            )),
        0
      ) * 100
    ) / 100;

  const employeeIdFromStore = useSelector(
    (state) => state.userDetails?.user?.employee_id
  );

  const [couponData, setCouponData] = useState({});

  const gettingActiveCustomerInfo = useCallback(async () => {
    const token = get(store.getState(), "userLogin.userInfo.token");
    const config = {
      headers: {
        token: token,
      },
    };
    await orders
      .get(
        `/customer_info?mobile_no=${customerInfoRenderData?.mobile_no}`,
        config
      )
      .then((res) => {
        if (res.data.data === "Token does not match") {
          sessionStorage.clear();
          window.location.href = "/login";
        }
        setActiveCustomerData(res.data.data);
        if (res.data.status === "success") {
          sessionStorage.setItem("customerInfo", JSON.stringify(res.data.data));
          return;
        }
        if (res.data.status === "failure") {
          return;
        }
      });
  }, [customerInfoRenderData]);

  const [couponCode, setCouponCode] = useState("");

  // CUSTOMER TYPE DISCOUNT STATE
  // const defaultDiscount = 0;
  const [displayCustomerTypeDiscount, setDisplayCustomerTypeDiscount] =
    useState(0);

  const [CustomerTypeDiscountAmount, setcda] = useState(0);

  useEffect(() => {
    const b_dis = activeCustomerData?.customer_role?.barista_disc_percent;
    const k_dis = activeCustomerData?.customer_role?.kitchen_disc_perc;
    let dis = 0;
    cartItems.forEach((item) => {
      let p = (item.add_on_price + item.price) * item.qty;
      if (item.product_type === "Barista") dis += (b_dis * 0.01 * p);
      else dis += (k_dis * 0.01 * p);
    });
    setcda(dis);
  }, [cartItems, activeCustomerData])
  // const CustomerTypeDiscountAmount =
  //   (activeCustomerData?.customer_role?.disc_percent * subTotalAmount) / 100;

  // const GoldCustomerTypeDiscount = (20 * subTotalAmount) / 100;
  // const PlatinumCustomerTypeDiscount = (30 * subTotalAmount) / 100;

  useEffect(() => {
    if (activeCustomerData?.customer_role?.customer_type === "Silver") {
      setDisplayCustomerTypeDiscount(CustomerTypeDiscountAmount.toFixed(2));
    } else if (activeCustomerData?.customer_role?.customer_type === "Gold") {
      setDisplayCustomerTypeDiscount(CustomerTypeDiscountAmount.toFixed(2));
    } else if (
      activeCustomerData?.customer_role?.customer_type === "Platinum"
    ) {
      setDisplayCustomerTypeDiscount(CustomerTypeDiscountAmount.toFixed(2));
    } else {
      setDisplayCustomerTypeDiscount(0);
    }
  }, [activeCustomerData, subTotalAmount, CustomerTypeDiscountAmount]);

  const [discountAmount, setDiscountAmount] = useState(0);
  const [displayTotalAfterDiscount, setDisplayTotalAfterDiscount] =
    useState(totalAmount);
  const [displayTotalAmountAfterDiscper, setDisplayTotalAmountAfterDiscper] =
    useState(totalAmount);

  const discountPercAmount = (discountOnly?.disc_percent / 100) * totalAmount;
  useEffect(() => {
    if (hasApplyDiscount) {
      if (discountOnly.flat_discount != null) {
        setDisplayTotalAfterDiscount(totalAmount - discountOnly.flat_discount);
        setDiscountAmount(discountOnly.flat_discount);
        setCouponCode(discountOnly.coupon_code);
      } else {
        setDisplayTotalAmountAfterDiscper(totalAmount - discountPercAmount);
        setDiscountAmount(displayTotalAmountAfterDiscper);
        setCouponCode(discountOnly.coupon_code);
      }
    }
  }, [
    hasApplyDiscount,
    totalAmount,
    discountOnly,
    discountPercAmount,
    displayTotalAmountAfterDiscper,
  ]);

  const changeAmount =
    Math.round(
      (totalReceived -
        (hasApplyDiscount ? displayTotalAfterDiscount : totalAmount)) *
      100
    ) / 100;

  const ToggleOrderItemsSidebar = () => {
    isOpenOrderItemsSidebar === true
      ? setIsOpenOrderItemsSidebar(false)
      : setIsOpenOrderItemsSidebar(true);
  };
  const ToggleOrderhistorySidebar = () => {
    isOpenOrderHistorySidebar === true
      ? setIsOpenOrderHistorySidebar(false)
      : setIsOpenOrderHistorySidebar(true);
  };

  const disableTrashIcon = cartItems.length === 0 && !customerInfoRender;

  useEffect(() => {
    if (cartItems.length === 0 && customerInfoRender === null) reset();
  }, [cartItems, customerInfoRender, reset]);

  useEffect(() => {
    gettingActiveCustomerInfo();
  }, [gettingActiveCustomerInfo]);

  useEffect(() => {
    setStartFunction(() => start);
  }, [setStartFunction, start]);

  const [bdayCus, setBdayCus] = useState([]);

  async function onToggleBirthday() {
    sessionStorage.setItem("bday", "false");

    try {
      const token = get(store.getState(), "userLogin.userInfo.token");

      const config = {
        headers: {
          token: token,
        },
      };
      const result = await orders.get(`/get_birthday_numbers/1`, config);
      if (result.data["status"] === "success") {
        setBdayCus(result.data.data);
      }
    } catch (error) { }
  }

  useEffect(() => {
    if (!bdayCus.length) {
      onToggleBirthday();
    }
    // const customerData =
    //   bdayCus.find(
    //     (customer) =>
    //       get(customer, "mobile_no") === activeCustomerData?.mobile_no
    //   ) ||
    //   null;

    if (
      (bdayCus[0]?.mobile_no && bdayCus[0]?.name) ===
      (activeCustomerData?.mobile_no && activeCustomerData?.first_name)
    ) {
      sessionStorage.setItem("bday", "true");
    } else {
      sessionStorage.setItem("bday", "false");
    }
  }, [activeCustomerData, bdayCus]);

  const [typeColorLight, setTypeColorLight] = useState('bg-[#ff8585]');
  const [typeColorDark, setTypeColorDark] = useState("text-[#c40000]");
  useEffect(() => {
    let type = customerInfoRenderData.customer_type || activeCustomerData?.customer_role?.customer_type;
    if (type === "Gold") {
      setTypeColorDark("text-[#958101]");
      setTypeColorLight('bg-[#e8ffa9]')
    }
    else if (type === "Silver") {
      setTypeColorDark("text-[#777777]");
      setTypeColorLight('bg-[#F0F0F0]')
    }
    else if (type === "Platinum") {
      setTypeColorDark("text-[#f4f4f4]");
      setTypeColorLight('bg-[#989898]')
    }
    else {
      setTypeColorDark("text-[#c40000]");
      setTypeColorLight('bg-[#FFB3B3]')
    }
  }, [
    customerInfoRenderData.customer_type, activeCustomerData?.customer_role?.customer_type])

  return (
    <>
      <div className="w-full md:w-1/3 h-screen fixed right-0 bg-bgColor2 z-50">
        <div className="top-bar-right-home m-[5px] p-1 fixed top-0 rounded-[10px] shadow-sm bg-slate-50 ">
          <div className="flex justify-between items-center">
            <div className=" flex items-center justify-start w-1/3 gap-2">
              <button
                className="button-pimary-light p-2 bg-primaryLightColor25 text-primaryColor rounded-[10px]"
                onClick={() => {
                  setIsOpenConfirm(true);
                  // reset();
                }}
                disabled={disableTrashIcon}
              >
                <TrashIcon className="h-6 w-6" />
              </button>
              {/*               <span className="font-bold">{duration}</span>
               */}{" "}
            </div>

            <div className=" w-1/3 flex  justify-center items-center">
              <h6 className="font-bold text-primaryColor  text-lg ">
                Cart({cartItems.length})
              </h6>
            </div>

            <div className=" w-1/3 flex justify-end items-center ">
              {/* <button
                className="button-pimary-light p-2 bg-primaryLightColor25 text-primaryColor rounded-[10px] "
                onClick={ToggleUserSidebar}
              >
                <UserAddIcon className="h-6 w-6" />
                
              </button> */}
              <div className="p-2 bg-primaryLightColor25 text-primaryColor rounded-[10px] ">
                <span className="font-bold">{duration}</span>
              </div>
            </div>
          </div>
        </div>

        {/*  {sessionStorage.getItem("customerInfo") !== null ? (
          <div className="top-bar-right-home m-[5px] fixed top-[55px] rounded-[10px] shadow-sm bg-slate-50 ">
            <div className="flex justify-between items-start">
              <div className=" block items-center gap-1">
                <div className="flex flex-wrap gap-2 m-2">
                  <h6 className="font-bold text-gray-700 text-md flex gap-1 items-center ml-1 capitalize">
                    <UserCircleIcon className="h-5 w-5" />
                    {customerInfoRenderData.first_name}
                  </h6>
                  <span className="bg-red-200 text-red-500 font-bold px-2 py-1 text-xs rounded-[10px]">
                    {customerInfoRenderData.customer_type}
                  </span>
                  {customerInfoRenderData.first_name === "Guest" ? (
                    ""
                  ) : (
                    <>
                      <div className="text-xs px-2 py-1 rounded-[10px] text-white bg-primaryColor">Wallet : ₹ {activeCustomerData !== undefined && activeCustomerData.wallet_balance}</div>
                      <div className="text-xs px-2 py-1 rounded-[10px] text-white bg-gray-800 cursor-pointer" onClick={() => setIsMoneyOpen(true)}>Add Money</div>
                    </>
                  )}
                </div>



                {customerInfoRenderData.first_name === "Guest" ? (
                  ""
                ) : (
                  <div className="flex justify-start gap-2 items-center grow m-2">
                    <span className="bg-primaryLightColorInput text-primaryColor font-bold px-2 py-0.5 text-xs rounded-[10px]">
                      Days Left <br />
                      <span>{customerInfoRenderData.memb_days_left} Days</span>
                    </span>
                    <span className="bg-primaryLightColorInput text-primaryColor font-bold px-2 py-0.5 text-xs rounded-[10px]">
                      Upg. Amt.
                      <br />
                      <span>
                        {" "}
                        &#8377;{customerInfoRenderData.memb_upg_amount || 0}
                      </span>
                    </span>
                    <span className="bg-primaryLightColorInput text-primaryColor font-bold px-2 py-0.5 text-xs rounded-[10px]">
                      Reduce Amt.
                      <br />
                      <span>
                        {" "}
                        &#8377;
                        {Math.round(customerInfoRenderData.memb_reduce_amount) ||
                          0}
                      </span>
                    </span>
                    <span className="bg-primaryLightColorInput text-primaryColor font-bold px-2 py-0.5 text-xs rounded-[10px]">
                      Upg. cat. <br />
                      <span> {customerInfoRenderData.memb_upg_categ}</span>
                    </span>
                  </div>)}
              </div>

              {customerInfoRenderData.first_name === "Guest" ? (
                ""
              ) : (
                <button
                  className="button-pimary-light p-2 bg-primaryLightColor25 text-primaryColor rounded-[10px] m-2"
                  onClick={ToggleOrderhistorySidebar}
                >
                  <DocumentTextIcon
                    className="h-6 w-6"
                    onClick={() => {
                      dispatch(
                        getLastCustomerOrderAction(
                          customerInfoRenderData?.mobile_no
                        )
                      );
                    }}
                  />
                </button>
              )}
            </div>
          </div>
        ) : (
          <div className="top-bar-right-home m-[5px] p-1 fixed top-[55px] rounded-[10px] shadow-sm bg-slate-50">
            <div className="flex justify-between items-center px-2">
              <h6 className="font-bold text-primaryColor">
                First to add New Customer
              </h6>
              <button
                className="button-pimary-light p-2 bg-primaryLightColor25 text-primaryColor rounded-[10px] font-bold"
                onClick={ToggleUserSidebar}
              >
                Add New Customer
              </button>
            </div>
          </div>
        )} */}

        {sessionStorage.getItem("customerInfo") !== null ? (
          <div className="top-bar-right-home m-[5px] fixed top-[55px] rounded-[10px] shadow-sm bg-slate-50 p-1">
            <div className="flex justify-between items-center bg-primaryLightColorInput  p-1.5 rounded-[10px]">
              <div className="flex flex-row gap-1">
                <h6 className="font-bold text-gray-700 text-md flex gap-1 items-center capitalize cursor-pointer" onClick={() => {
                  ToggleUserSidebar();
                  onToggleBirthday();
                }}>
                  <UserCircleIcon className="h-5 w-5" />
                  {customerInfoRenderData.first_name ||
                    activeCustomerData?.first_name}
                </h6>
                <span className={`${typeColorLight} ${typeColorDark} font-bold px-2 py-1 text-xs rounded-[10px]`}>
                  {customerInfoRenderData.customer_type ||
                    activeCustomerData?.customer_role?.customer_type}
                </span>
              </div>

              {customerInfoRenderData.first_name === "Guest" ? (
                ""
              ) : (
                <div className="flex flex-wrap gap-1 bg-primaryLightColor25 border-2 border-dashed border-primaryColor rounded-[20px]">
                  <div className="text-xs  rounded-[10px] font-bold  text-primaryColor relative pr-7 py-1 pl-2 flex">
                    <IoWalletOutline className="w-4 h-4" />
                    &nbsp;₹
                    {activeCustomerData !== undefined &&
                      activeCustomerData.wallet_balance}
                  </div>
                  <div
                    className="text-xs px-1 py-1 rounded-[10px] text-white bg-gray-800 cursor-pointer absolute right-[14px] top-[13px]"
                    onClick={() => setIsMoneyOpen(true)}
                  >
                    <PlusIcon className="w-3.5 h-3.5 text-white" />
                  </div>
                </div>
              )}
            </div>

            {customerInfoRenderData.first_name === "Guest" ? (
              ""
            ) : (
              <div className="flex justify-between items-center gap-1 mt-1">
                <div className="flex flex-wrap justify-start gap-1 items-center grow ">
                  <span className="bg-primaryLightColorInput text-primaryColor font-bold px-2 py-0.5 text-xs rounded-[10px]">
                    Days Left
                    <span> {customerInfoRenderData.memb_days_left} Days</span>
                  </span>
                  <span className="bg-primaryLightColorInput text-primaryColor font-bold px-2 py-0.5 text-xs rounded-[10px]">
                    Upg. Amt.
                    <span>
                      {" "}
                      &#8377;
                      {Math.round(
                        customerInfoRenderData.memb_upg_amount * 100
                      ) / 100 || 0}
                    </span>
                  </span>

                  <span className="bg-primaryLightColorInput text-primaryColor font-bold px-2 py-0.5 text-xs rounded-[10px]">
                    Retaining Amt.
                    <span>
                      {" "}
                      &#8377;
                      {Math.round(
                        customerInfoRenderData.memb_reduce_amount * 100
                      ) / 100 || 0}
                    </span>
                  </span>
                  <span className="bg-primaryLightColorInput text-primaryColor font-bold px-2 py-0.5 text-xs rounded-[10px]">
                    Upg. cat.
                    <span> {customerInfoRenderData.memb_upg_categ}</span>
                  </span>
                </div>
                {customerInfoRenderData.first_name === "Guest" ? (
                  ""
                ) : (
                  <button
                    className="p-2 bg-primaryLightColor25 text-primaryColor rounded-[10px]"
                    onClick={ToggleOrderhistorySidebar}
                  >
                    <DocumentTextIcon
                      className="h-6 w-6"
                      onClick={() => {
                        if (state.lastCustomerOrder.lastCustomerOrders.length === 0) {
                          dispatch(
                            getLastCustomerOrderAction(
                              customerInfoRenderData?.mobile_no
                            )
                          );
                        }
                      }}
                    />
                  </button>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="top-bar-right-home m-[5px] p-1 fixed top-[55px] rounded-[10px] shadow-sm bg-slate-50">
            <div className="flex justify-between items-center">
              {/* <h6 className="font-bold text-primaryColor">
                First to add New Customer
              </h6> */}
              <button
                className="button-pimary-light p-2 bg-primaryLightColor25 text-primaryColor rounded-[10px] font-bold w-full"
                onClick={() => {
                  ToggleUserSidebar();
                  onToggleBirthday();
                }}
              >
                Add New Customer
              </button>
            </div>
          </div>
        )}

        {/*  {sessionStorage.getItem("customerInfo") !== null ? (
          <div className="top-bar-right-home m-[5px] fixed top-[55px] rounded-[10px] shadow-sm bg-slate-50">
            <div className="flex justify-between items-center bg-primaryLightColorInput text-primaryColor rounded-tl-[10px] rounded-tr-[10px]  p-2">
              <div className="flex flex-wrap gap-2">
                <h6 className="font-bold text-gray-700 text-md flex gap-1 items-center ml-1 capitalize">
                  <UserCircleIcon className="h-5 w-5" />
                  {customerInfoRenderData.first_name}
                </h6>
                <span className="bg-red-200 text-red-500 font-bold px-2 py-1 text-xs rounded-[10px]">
                  {customerInfoRenderData.customer_type}
                </span>
              </div>
              <div>
                <div className="flex flex-wrap gap-1 bg-white p-1 rounded-[20px]">
                  <div className="text-xs  rounded-[10px] text-white bg-primaryColor relative pr-7 py-1 pl-2">Wallet : ₹ {activeCustomerData !== undefined && activeCustomerData.wallet_balance}</div>
                  <div className="text-xs px-1 py-1 rounded-[10px] text-white bg-gray-800 cursor-pointer absolute right-3" onClick={() => setIsMoneyOpen(true)}><PlusIcon className="w-4 h-4 text-white" /></div>
                </div>
                {customerInfoRenderData.first_name === "Guest" ? (
                  ""
                ) : (
                  <button
                    className="button-pimary-light p-2 bg-primaryLightColor25 text-primaryColor rounded-[10px]"
                    onClick={ToggleOrderhistorySidebar}
                  >
                    <DocumentTextIcon
                      className="h-6 w-6"
                      onClick={() => {
                        dispatch(
                          getLastCustomerOrderAction(
                            customerInfoRenderData?.mobile_no
                          )
                        );
                      }}
                    />
                  </button>
                )}
              </div>
            </div>

            {customerInfoRenderData.first_name === "Guest" ? (
              ""
            ) : (
              <div className="flex justify-between items-center mb-5 bg-primaryLightColorInput text-primaryColor rounded-bl-[20px] rounded-br-[20px]">


              </div>
            )}

            {customerInfoRenderData.first_name === "Guest" ? (
              ""
            ) : (
              <div className="flex justify-start gap-2 items-center grow">
                <span className="bg-primaryLightColorInput text-primaryColor font-bold px-2 py-1.5 text-xs rounded-[10px]">
                  Days Left &nbsp;
                  <span>{customerInfoRenderData.memb_days_left} Days</span>
                </span>
                <span className="bg-primaryLightColorInput text-primaryColor font-bold px-2 py-0.5 text-xs rounded-[10px]">
                  Upg. Amt.
                  <span>
                    {" "}
                    &#8377;{(Math.round(customerInfoRenderData.memb_upg_amount * 100) / 100) || 0}
                  </span>
                </span>
                <span className="bg-primaryLightColorInput text-primaryColor font-bold px-2 py-0.5 text-xs rounded-[10px]">
                  Reduce Amt.
                  <span>
                    {" "}
                    &#8377;
                    {Math.round(customerInfoRenderData.memb_reduce_amount) ||
                      0}
                  </span>
                </span>
                <span className="bg-primaryLightColorInput text-primaryColor font-bold px-2 py-0.5 text-xs rounded-[10px]">
                  Upg. cat.
                  <span> {customerInfoRenderData.memb_upg_categ}</span>
                </span>
              </div>)}

          </div>
        ) : (
          <div className="top-bar-right-home m-[5px] p-1 fixed top-[55px] rounded-[10px] shadow-sm bg-slate-50">
            <div className="flex justify-between items-center px-2">
              <h6 className="font-bold text-primaryColor">
                First to add New Customer
              </h6>
              <button
                className="button-pimary-light p-2 bg-primaryLightColor25 text-primaryColor rounded-[10px] font-bold"
                onClick={ToggleUserSidebar}
              >
                Add New Customer
              </button>
            </div>
          </div>
        )} */}

        {/* ${sessionStorage.getItem("customerInfo") !== null
          ? "top-[178px]"
          : "top-[146px]"
        } */}

        <div key={renderagaiankey}
          className={`middle-bar-right-home m-[5px] p-1 rounded-[10px] absolute bottom-[55px] shadow-sm bg-gray-50 ${sessionStorage.getItem("customerInfo") !== null
            ? "top-[160px]"
            : "top-[112px] h-[calc(100vh-125px)]"
            } ${customerInfoRenderData.first_name === "Guest"
              ? "top-[105px] h-[calc(100vh-120px)]"
              : "top-[160px] h-[calc(100vh-175px)]"
            } ${cartItems.length === 0 ? "" : "h-[calc(100vh-315px)]"}  `}
        >
          {cartItems.length === 0 ? (
            <div className="flex flex-col justify-center h-[100%] items-center py-3">
              <img src={emptyCart} alt="no items" style={{ height: "100px" }} />
              <h6 className="font-bold mt-1">No Product in Your Cart</h6>
            </div>
          ) : (
            <>
              {cartItems &&
                cartItems.map((item, i) => {
                  return <RightDivCartItem key={i} item={item} />;
                })}
            </>
          )}
        </div>

        {cartItems.length === 0 ? (
          ""
        ) : (
          <div className="bottom-bar-right-home bottom-0 m-[5px] p-1 fixed rounded-[10px] shadow-sm bg-slate-50">
            <div className="flex justify-between items-center border-b border-gray-200 p-1">
              <h6 className="font-bold text-2xl text-gray-900">Subtotal</h6>
              <h6 className="font-bold text-2xl text-gray-900">
                &#8377;
                {subTotalAmount}
              </h6>
            </div>
            <div className="flex justify-between items-center border-b border-gray-200 p-1">
              <h6 className="font-bold text-xl text-gray-700">
                Membership Discount
              </h6>
              <h6 className="font-bold text-xl text-gray-700">
                &#8377;
                {displayCustomerTypeDiscount}
              </h6>
            </div>

            <div className="flex mt-2 gap-2">
              <button
                type="button"
                className="inline-flex justify-center px-4 py-2 text-sm font-bold uppercase text-white bg-gray-800 border border-transparent rounded-[10px] hover:bg-gray-900 w-1/3 "
                onClick={() => {
                  if (!customerInfoRender) {
                    alert.error("Please Add customer first");
                  } else {
                    setIsOnHoldOpen(!isOpen);
                  }
                }}
              >
                Hold
              </button>
              <button
                type="button"
                className="inline-flex justify-center px-4 py-2 text-sm font-bold uppercase text-white bg-primaryColor border border-transparent rounded-[10px] hover:bg-primaryDarkColor w-2/3"
                onClick={() => {
                  if (!customerInfoRender) {
                    alert.error("Please Add customer first");
                  } else {
                    setIsCheckoutOpen(!isCheckoutOpen);
                  }
                }}
              >
                Checkout
              </button>
            </div>
          </div>
        )}
      </div>

      <UserSiderbar
        isOpenUserSidebar={isOpenUserSidebar}
        setIsOpenUserSidebar={setIsOpenUserSidebar}
        setCustomerInfoRenderData={setCustomerInfoRenderData}
        start={start}
        stop={stop}
        reset={reset}
        customerInfoRender={customerInfoRender}
        customerInfoRenderData={customerInfoRenderData}
      />

      <CheckoutModal
        isCheckoutOpen={isCheckoutOpen}
        setIsCheckoutOpen={setIsCheckoutOpen}
        displayCustomerTypeDiscount={displayCustomerTypeDiscount}
        isopenhold={isOnHoldOpen}
        setIsOnHoldOpen={setIsOnHoldOpen}
        customerInfoRenderData={customerInfoRenderData}
        reset={reset}
        stop={stop}
        duration={duration}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
        orderType={orderType}
        setOrderType={setOrderType}
        paymentChecked={paymentChecked}
        setPaymentChecked={setPaymentChecked}
        orderTypeChecked={orderTypeChecked}
        setOrderTypeChecked={setOrderTypeChecked}
        activeCustomerData={activeCustomerData}
        gettingActiveCustomerInfo={gettingActiveCustomerInfo}
      />
      <HoldModal
        isopenhold={isOnHoldOpen}
        setIsOnHoldOpen={setIsOnHoldOpen}
        subTotal={subTotalAmount}
        totalAmount={totalAmount}
        taxAmount={taxAmount}
        hasApplyDiscount={hasApplyDiscount}
        displayTotalAfterDiscount={displayTotalAfterDiscount}
        discountAmount={discountAmount}
        changeAmount={changeAmount}
        totalReceived={totalReceived}
        customerInfoRenderData={customerInfoRenderData}
        stop={stop}
        duration={duration}
        reset={reset}
      />

      <OrderItemsSidebar
        isOpenOrderItemsSidebar={isOpenOrderItemsSidebar}
        setIsOpenOrderItemsSidebar={setIsOpenOrderItemsSidebar}
        customerInfoRenderData={customerInfoRenderData}
      />
      <OrderHistorySidebar
        isOpenOrderHistorySidebar={isOpenOrderHistorySidebar}
        setIsOpenOrderHistorySidebar={setIsOpenOrderHistorySidebar}
        customerInfoRenderData={customerInfoRenderData}
      />
      <ConfirmationModalHome
        isOpenConfirm={isOpenConfirm}
        setIsOpenConfirm={setIsOpenConfirm}
        reset={reset}
      />
      <AddMoney
        isMoneyOpen={isMoneyOpen}
        setIsMoneyOpen={setIsMoneyOpen}
        gettingActiveCustomerInfo={gettingActiveCustomerInfo}
        customerInfoRenderData={customerInfoRenderData}
      />
    </>
  );
};

export default RightSectionHome;
